import React from 'react';
import LoaderSVG from '../../assets/svgs/loader.svg';
import './orderItems.styles.scss';

const OrderItems = ({
    isOrderDetailsItemsLoading,
    orderDetailsItems
}) => {

    return (
        <div className='item-container'>
            {
                isOrderDetailsItemsLoading && !orderDetailsItems ?

                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <img style={{ width: '50px' }} src={LoaderSVG} alt='' />
                    </div>

                    :
                    <>
                        {
                            orderDetailsItems && orderDetailsItems?.length ?
                                orderDetailsItems.map(item => (
                                    <div className='item-card'>
                                        <img className='card-img' src={item.image_url} alt="" />
                                        <label className="card-label">{item.name}</label>
                                        <span className='card-qty-label'>({item.quantity})</span>
                                    </div>

                                ))
                                :
                                <div style={{ textAlign: 'center', width: '100%', marginTop: '100px' }}>
                                    <span className="no-data-text">
                                        No Data Found!
                                    </span>
                                </div>
                        }
                    </>
            }
        </div>
    )
};

export default OrderItems;
