import FiltersActions from "../actions/filtersActions";

const FiltersReducer = (state = { isFitlersLoading: false, filtersHasError: false }, action) => {
  switch (action.type) {
    case FiltersActions.ORDER_HUB_FILTERS_REQUEST:
      return {
        ...state,
        isFitlersLoading: true,
        filters: null,
        filtersHasError:true
      };
    case FiltersActions.ORDER_HUB_FILTERS_SUCCESSFUL:
      return {
        ...state,
        isFitlersLoading: false,
        filters: action.data,
        filtersHasError:true
      };
    case FiltersActions.ORDER_HUB_FILTERS_FAILED:
      return {
        ...state,
        errorMessage: {},
        isFitlersLoading: false,
        filtersHasError:true,
        filters: null
      };
    default:
      return {
        ...state
      };
  }
};

export default FiltersReducer;
