import AuthActions from "../actions/authActions";
import CustomToast from '../../components/custom-toast/customToast';
import Path from "../../services/paths";
import axios from 'axios';

class AuthMiddleware {
    static authenticate(username, password, history) {
        return dispatch => {
            dispatch(AuthActions.authentication());
            axios({
                method: "POST",
                url: Path.AUTHENTICATE,
                headers: {
                    "Content-Type": "application/json"
                },
                data: JSON.stringify({
                    "username": username,
                    "password": password
                })
            })
                .then(res => {
                    if (res.status === 200) {
                        
                        var today = new Date();
                        var tomorrow = new Date(today.getTime() + (24 * 60 * 60 * 1000));

                        localStorage.setItem('wpd_org_usr_tkn_expd',JSON.stringify(tomorrow));
                        localStorage.setItem("wpd_org_usr_tkn", JSON.stringify(res.data));
                        dispatch(AuthActions.authenticationSuccessul(res.data));
                        dispatch(AuthMiddleware.getUserData(res.data.access));
                    } else {
                        if (res.detail) {
                            CustomToast('error', 'No active account found with the given credentials')
                            dispatch(AuthActions.authenticationFailed());
                        }
                    }
                })
                .catch(error => {
                    if (error.response?.status === 401) {
                        CustomToast('error', 'No active account found with the given credentials')
                    }
                    dispatch(AuthActions.authenticationFailed());
                });
        };
    }

    static refreshToken(refreshTkn) {
        console.log(refreshTkn)
        return dispatch => {
            // dispatch(AuthActions.authentication());
            axios({
                method: "POST",
                url: Path.REFRESH_TOKEN,
                headers: {
                    "Content-Type": "application/json"
                },
                data: JSON.stringify({
                    "refresh": refreshTkn
                })
            })
                .then(res => {
                    if (res.status === 200) {

                        var today = new Date();
                        var tomorrow = new Date(today.getTime() + (24 * 60 * 60 * 1000));
                        res.data['refresh'] = refreshTkn;

                        localStorage.setItem('wpd_org_usr_tkn_expd',JSON.stringify(tomorrow));
                        localStorage.setItem("wpd_org_usr_tkn", JSON.stringify(res.data));
                        window.location.reload();
                        // dispatch(AuthActions.authenticationSuccessul(res.data));
                        // dispatch(AuthMiddleware.getUserData(res.data.access));
                    } else {
                        if (res.detail) {
                            CustomToast('error', 'No active account found with the given credentials')
                            dispatch(AuthActions.authenticationFailed());
                        }
                    }
                })
                .catch(error => {
                    if (error.response?.status === 401) {
                        // CustomToast('error', 'No active account found with the given credentials')
                        dispatch(AuthMiddleware.logOut());
                    }
                    // dispatch(AuthActions.authenticationFailed());
                });
        };
    }


    static getUserData(accessToken) {
        let user_tkn = JSON.parse(localStorage.getItem('wpd_org_usr_tkn'))
        return dispatch => {
            dispatch(AuthActions.userDataLoading());
            axios({
                method: "GET",
                url: Path.USER_DATA,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `JWT ${accessToken}`
                }
            })
                .then(res => {
                    if (res.status === 200) {
                        localStorage.setItem("wpd_org_usr", JSON.stringify(res.data));
                        dispatch(AuthActions.userDataSuccessul(res.data));
                    } else {
                        if (res.detail) {
                            dispatch(AuthActions.userDataFailed());
                        }
                    }
                })
                .catch(error => {
                    if (error.response?.status === 401) {
                        CustomToast('error', 'No user data found!')
                        dispatch(AuthMiddleware.refreshToken(user_tkn.refresh))
                    }
                    dispatch(AuthActions.userDataFailed());
                });
        }
    };


    static logOut() {
        return dispatch => {
            dispatch(AuthActions.logout());
        };
    }
}

export default AuthMiddleware;
