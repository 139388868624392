import React from 'react';
import TopNavBar from '../top-nav-bar/topNavBar';
import SideNavBar from '../side-nav-bar/sideNavBar';
import './layout.styles.scss';

const Layout = ({ children }) => (
    <>
        <TopNavBar />
        <main style={{ display: 'flex' }}>
            <SideNavBar />
            <div className='content-container'>
                {children}
            </div>
        </main>
    </>
);

export default Layout;
