class FiltersActions {
    static ORDER_HUB_FILTERS_REQUEST = "ORDER_HUB_FILTERS_REQUEST";
    static ORDER_HUB_FILTERS_SUCCESSFUL = "ORDER_HUB_FILTERS_SUCCESSFUL";
    static ORDER_HUB_FILTERS_FAILED = "ORDER_HUB_FILTERS_FAILED";

    static orderHubFiltersRequest() {
        return {
            type: this.ORDER_HUB_FILTERS_REQUEST
        };
    }
    static orderHubFiltersSuccessul(data) {
        return {
            type: this.ORDER_HUB_FILTERS_SUCCESSFUL,
            data
        };
    }
    static orderHubFiltersFailed(error) {
        return {
            type: this.ORDER_HUB_FILTERS_FAILED,
            error
        };
    }
}

export default FiltersActions;
