import AuthActions from "../actions/authActions";

const AuthReducer = (
  state = { isLoggedIn: false, isLoading: false, isUserDataLoading: false, userDataHasError:false },
  action
) => {
  switch (action.type) {
    case AuthActions.AUTHENTICATION_REQUEST:
      return {
        ...state,
        isLoggedIn: false,
        isLoading: true,
        user:''
      };
    case AuthActions.AUTHENTICATION_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        isLoggedIn: true,
        user: action
      };
    case AuthActions.AUTHENTICATION_FAILED:
      return {
        ...state,
        isLoading: false,
        isLoggedIn: false
      };


      // USER DATA

      case AuthActions.USER_DATA_REQUEST:
      return {
        ...state,
        isUserDataLoading: true,
        userDataHasError: false,
        userData: null
      };
    case AuthActions.USER_DATA_SUCCESSFUL:
      return {
        ...state,
        isUserDataLoading: false,
        userDataHasError: false,
        userData: action
      };
    case AuthActions.USER_DATA_FAILED:
      return {
        ...state,
        isUserDataLoading: false,
        userDataHasError: true,
        userData: null
      };


    case AuthActions.LOGOUT:
      return {};
    default:
      return {
        ...state
      };
    //   break;
  }
};

export default AuthReducer;
