const filterDateFormater = (value) => {  // returns yyyy-mm-dd => 21-2-2021 for APIs
    let date = new Date(value);
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    return `${year}-${month}-${day}`;
}

const dateFormater2 = (value) => {  // returns mm dd, yyyy => Feb 21, 2021
    let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
			"Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
		];
    let date = new Date(value);
    if(Boolean(date)){
        let day = date.getDate();
        let month = monthNames[date.getMonth()];
        let year = date.getFullYear();
        return `${month} ${day}, ${year}`;
    }else{
        return null
    }

}


const UtilFunctions = {
    filterDateFormater,
    dateFormater2
}

export default UtilFunctions;