import React, { useState } from 'react';
import './orderScreenFilters.styles.scss';
import SearchIcon from '../../../assets/images/search-icon.svg';
import ChevronIcon from '../../../assets/images/chevron-down.svg';
import LoaderSVG from '../../../assets/svgs/loader.svg';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const OrderScreenFilters = (props) => {

    const [showFilters, setShowFilters] = useState(false)

    const handleBooleanFilterValue = (ind, value, currentValue) => {
        if(value === currentValue){
            props.handleOthersBooleanFilters(ind,null)
        }else{
            props.handleOthersBooleanFilters(ind,value)
        }
    }


    return (

        <div className={showFilters ? 'order-filters-container' : 'order-filters-container-hidden'}>
            {
                props.isFitlersLoading ?

                    <img style={{ width: '50px' }} src={LoaderSVG} alt='' />

                    :

                    <>
                        <div className='visible-filter-row'>

                            <div className='quick-summary-input-box'>
                                <img className='search-icon' src={SearchIcon} alt='' />
                                <input className='quick-summary-input' placeholder='Quick Order Query' name='orderQuery' value={props.orderQuery} onChange={props.handleOrderQueryChange} />
                            </div>

                            <div className='quick-summary-select-search-by'>
                                <Select
                                    placeholder='Search By'
                                    isClearable
                                    value={props.selectedSearchByOption}
                                    onChange={props.handleSearchByOptionChange}
                                    options={
                                        props.searchByFilters && props.searchByFilters.map(item => {
                                            return {
                                                value: item.enum,
                                                label: item.label
                                            }
                                        })
                                    }
                                />
                            </div>

                            <div className='quick-summary-filter-btn-group'>
                                <button className='quick-summary-filter-search-btn' onClick={props.handleSearch} >
                                    Search
                                </button>

                                <button className='quick-summary-filter-clear-btn' onClick={props.handleClearFilters}>
                                    Clear
                                </button>
                            </div>

                            <div className='chevron-btn' onClick={() => setShowFilters(!showFilters)} >
                                <img src={ChevronIcon} style={showFilters ? { transform: 'rotate(180deg)', transition: '0.2s' } : {}} alt='' />
                            </div>

                        </div>

                        <div className={showFilters ? 'hidden-filter-row' : 'hidden-filter-row-hide'}>

                            <div className='common-hidden-input-box'>

                                <div className='common-hidden-input-label-box'>
                                    <label>Order Number</label>
                                    <input className='common-hidden-input' name='orderNumber' placeholder='Order Number' value={props.orderNumber} onChange={props.handleOrderNumberChange} />
                                </div>
                                <div className='common-hidden-input-label-box'>
                                    <label>Invoice Number</label>
                                    <input className='common-hidden-input' name='invoiceNumber' placeholder='Invoice Number' value={props.invoiceNumber} onChange={props.handleInvoiceNumberChange} />
                                </div>
                                <div className='common-hidden-input-label-box'>
                                    <label>Part Number</label>
                                    <input className='common-hidden-input' name='partNumber' placeholder='Part Number' value={props.partNumber} onChange={props.handlePartNumberChange} />
                                </div>
                            </div>

                            <div className='date-range-box'>
                                <label>Date Range</label>

                                <div className='date-range-input-box'>
                                    <DatePicker className='date-range-input' placeholderText='Start Date' dateFormat="MM-dd-yyyy" isClearable selected={props.dateStart} onChange={props.handleStartDateChange} />
                                    <DatePicker className='date-range-input' placeholderText='End Date' dateFormat="MM-dd-yyyy" isClearable selected={props.dateEnd} onChange={props.handleEndDateChange} />
                                </div>
                            </div>

                        </div>

                        <div className={showFilters ? 'hidden-filter-row' : 'hidden-filter-row-hide'}>

                            <div className='order-status-container'>
                                <label className='heading-label'>Order Status</label>
                                <div style={{ marginTop: '4px', columnCount: 2 }}>
                                    {
                                        props.orderStatusFilters && props.orderStatusFilters.map((item, ind) => (
                                            <span className='checkbox-item' key={item.label + ind}>
                                                <input type='checkbox' checked={item.flag} onChange={() => props.handleOrderStatusFilters(ind)} />
                                                <label>{item.label}</label>
                                            </span>
                                        ))
                                    }
                                </div>
                            </div>

                            <div className='order-status-container' style={{ marginLeft: '16px' }}>
                                <label className='heading-label'>Marketplace</label>
                                <div style={{ marginTop: '4px', columnCount: 2 }}>
                                    {
                                        props.marketPlaceFilters && props.marketPlaceFilters.map((item, ind) => (
                                            <span className='checkbox-item' key={item.label + ind}>
                                                <input type='checkbox' checked={item.flag} onChange={() => props.handleMarketPlaceFilter(ind)} />
                                                <label>{item.label}</label>
                                            </span>
                                        ))
                                    }
                                </div>
                            </div>

                            <div className='order-status-container' style={{ marginLeft: '16px' }}>
                                <label className='heading-label'>Shipping Carrier</label>
                                <div style={{ marginTop: '4px', columnCount: 2 }}>
                                    {
                                        props.shippingCarrierFilters && props.shippingCarrierFilters.map((item, ind) => (
                                            <span className='checkbox-item' key={item.label + ind}>
                                                <input type='checkbox' checked={item.flag} onChange={() => props.handleShippingCarrierFilters(ind)} />
                                                <label>{item.label}</label>
                                            </span>
                                        ))
                                    }
                                </div>
                            </div>

                            <div className='order-status-container' style={{ marginLeft: '16px' }}>
                                <label className='heading-label'>Price</label>
                                <div style={{ display: 'flex', flexDirection: 'column', marginTop: '4px' }}>
                                    <span className='price-input-box'>
                                        <label>Min.</label>
                                        <input className='price-input' type='number' name='minPrice' value={props.minPrice} onChange={props.handleMinPriceChange} />
                                    </span>
                                    <span className='price-input-box'>
                                        <label>Max.</label>
                                        <input className='price-input' type='number' name='maxPrice' value={props.maxPrice} onChange={props.handleMaxPriceChange} />
                                    </span>
                                </div>
                            </div>

                            <div className='order-status-container' style={{ marginLeft: '16px' }}>
                                <label className='heading-label'>Cost</label>
                                <div style={{ display: 'flex', flexDirection: 'column', marginTop: '4px' }}>
                                    <span className='price-input-box'>
                                        <label>Min.</label>
                                        <input className='price-input' type='number' name='minCost' value={props.minCost} onChange={props.handleMinCostChange} />
                                    </span>
                                    <span className='price-input-box'>
                                        <label>Max.</label>
                                        <input className='price-input' type='number' name='maxCost' value={props.maxCost} onChange={props.handleMaxCostChange} />
                                    </span>
                                </div>
                            </div>

                            <div className='order-status-container' style={{ marginLeft: '16px' }}>
                                <label className='heading-label'>Others</label>
                                <div style={{ marginTop: '4px', columnCount: 2 }}>
                                    {
                                        props.othersBooleanFilters && props.othersBooleanFilters.map((item, ind) => (
                                            <span className='checkbox-item' key={item.label + ind}>
                                                {/* <input type='checkbox' checked={item.flag} onChange={() => props.handleOthersBooleanFilters(ind)} /> */}
                                                <span className='other-boolean-containers'>
                                                    <button style={item.flag ? { backgroundColor: '#307cc4',color:'#fff' } : {}} className='boolean-filters-btn' onClick={() => handleBooleanFilterValue(ind, true, item.flag)}>On</button>
                                                    <button style={item.flag === false ? { backgroundColor: '#307cc4',color:'#fff' } : {}} className='boolean-filters-btn' onClick={() => handleBooleanFilterValue(ind, false, item.flag)}>Off</button>
                                            </span>
                                            <label>{item.label}</label>
                                            </span>
                                ))
                            }
                                </div>
                        </div>

                    </div>
                    </>
            }
        </div >
    )
}

export default OrderScreenFilters;
