import React from 'react';
import ReactModal from 'react-modal';
import LoaderSVG from '../../assets/svgs/loader.svg';
import './customModal.styles.scss';

const CustomModal = ({
    modalIsOpen,
    handleCloseModal,
    image,
    isVendorImageLoading
}) => {

    return (
        <ReactModal
            isOpen={modalIsOpen}
            preventScroll={true}
            shouldCloseOnEsc={true}
            shouldCloseOnOverlayClick={true}
            onRequestClose={handleCloseModal}
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.75)'
                }
            }}
            ariaHideApp={false}
        >
            {
                isVendorImageLoading ?

                    <div style={{ width: '100%', height:'100%', display: 'flex', justifyContent: 'center',alignItems:'center' }}>
                        <img style={{ width: '50px' }} src={LoaderSVG} alt='' />
                    </div>

                    :
                    <img style={{ width: '625px' }} src={image} alt="" />
            }
        </ReactModal>
    )
};

export default CustomModal;
