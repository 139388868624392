import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import UtilFunction from '../../services/utilsFunctions';
import VendorOrderListMiddleware from '../../store/middlewares/vendorOrderListMiddleware';
import VendorOrderScreenFilters from '../../components/filters/vendor-order-screen-filter/vendorOrderFilters';
import VendorOrderScreenTable from '../../components/tables/vendor-order-table/vendorOrderTable';
import CustomModal from '../../components/custom-modal/customModal';
import './vendorOrdersScreen.styles.scss';

const VendorOrdersScreen = (props) => {

    const [pageNo, setPageNo] = useState(1);
    const [searchByFilters] = useState([
        { label: 'JUPITER DATE', value: 'JUPITER_DATE' },
        { label: 'DODGE DATE', value: 'DODGE_DATE' },
        { label: 'DODGE CLOSED', value: 'DODGE_CLOSED' },
        { label: 'SCAN DATE', value: 'SCAN_DATE' }
    ]);
    const [dodgeInvoice, setDodgeInvoice] = useState('');
    const [jupiterInvoice, setJupiterInvoice] = useState('');
    const [partNumber, setPartNumber] = useState('');
    const [dateStart, setDateStart] = useState(null);
    const [dateStartToDisplay, setDateStartToDisplay] = useState(null);
    const [dateEnd, setDateEnd] = useState(null);
    const [dateEndToDisplay, setDateEndToDisplay] = useState(null);
    const [selectedSearchByOption, setSearchByOption] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [totalPage, setTotalPage] = useState(0);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    useEffect(() => {
        callOrderListApi();
    }, [])           // eslint-disable-line

    useEffect(() => {
        if (props.vendorOrderList) {
            if (props.vendorOrderList.results?.length) {
                setTableData(props.vendorOrderList.results)
                setTotalPage(props.vendorOrderList.total_pages);
            }
        } else {
            setTableData([])
        }
    }, [props.vendorOrderList])

    const handleDodgeInvoiceChange = (e) => {
        e.preventDefault();
        setDodgeInvoice(e.target.value)
    }

    const handleJupiterInvoiceChange = (e) => {
        e.preventDefault();
        setJupiterInvoice(e.target.value)
    }

    const handlePartNumberChange = (e) => {
        e.preventDefault();
        setPartNumber(e.target.value)
    }

    const handleStartDateChange = (value) => {
        if (value) {
            let date = UtilFunction.filterDateFormater(value);
            setDateStart(date)
            setDateStartToDisplay(value)
        } else {
            setDateStart(null)
            setDateStartToDisplay(null)
        }
    }

    const handleEndDateChange = (value) => {
        if (value) {
            let date = UtilFunction.filterDateFormater(value);
            setDateEnd(date)
            setDateEndToDisplay(value)
        } else {
            setDateEnd(null)
            setDateEndToDisplay(null)
        }
    }

    const handleSearchByOptionChange = (selectvalue) => {
        if (selectvalue) {
            setSearchByOption(selectvalue)
        } else {
            setSearchByOption(null)
        }
    }

    const handlePageClick = (value) => {
        setPageNo(value.selected + 1)
        callOrderListApi(value.selected + 1)
    }

    const handleSearch = (e) => {
        e.preventDefault();
        setPageNo(1);
        callOrderListApi(1);
    }

    const callOrderListApi = (page) => {
        let pageNumber = page ? page : pageNo;
        props.getVendorOrderList(
            pageNumber,
            dodgeInvoice,
            jupiterInvoice,
            partNumber,
            selectedSearchByOption,
            dateStart,
            dateEnd
        )
    }

    const handleClearFilters = () => {

        setDodgeInvoice('');
        setJupiterInvoice('');
        setPartNumber('');
        setDateStart(null);
        setDateEnd(null);
        setDateStartToDisplay(null);
        setDateEndToDisplay(null);
        setSearchByOption(null);
        setPageNo(1);

        props.getVendorOrderList(
            1,
            '',         // dodgeInvoice
            '',         // jupiterInvoice
            '',         // partNumber
            null,       // selectedSearchByOption
            null,       // dateStart
            null,       // dateEnd
        )
    }

    const handleCloseModal = () => {
        setModalIsOpen(false)
    }

    const handleOpenModal = (value) => {
        setModalIsOpen(true)
        
        props.getVendorImage(value);
    }

    return (
        <div>

            <VendorOrderScreenFilters
                searchByFilters={searchByFilters}
                jupiterInvoice={jupiterInvoice}
                dodgeInvoice={dodgeInvoice}
                partNumber={partNumber}
                dateStart={dateStartToDisplay}
                dateEnd={dateEndToDisplay}
                selectedSearchByOption={selectedSearchByOption}

                handleDodgeInvoiceChange={handleDodgeInvoiceChange}
                handleJupiterInvoiceChange={handleJupiterInvoiceChange}
                handlePartNumberChange={handlePartNumberChange}
                handleStartDateChange={handleStartDateChange}
                handleEndDateChange={handleEndDateChange}
                handleSearchByOptionChange={handleSearchByOptionChange}
                handleSearch={handleSearch}
                handleClearFilters={handleClearFilters}
            />

            <VendorOrderScreenTable
                data={tableData}
                pageNo={pageNo}
                handlePageClick={handlePageClick}
                isVendorOrderListLoading={props.isVendorOrderListLoading}
                totalPg={totalPage}
                handleOpenModal={handleOpenModal}
            />

            <CustomModal
                modalIsOpen={modalIsOpen}
                handleCloseModal={handleCloseModal}
                image={props.vendorImage}
                isVendorImageLoading={props.isVendorImageLoading}
            />

        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        isVendorOrderListLoading: state.VendorOrderListReducer.isVendorOrderListLoading,
        vendorOrderList: state.VendorOrderListReducer.vendorOrderList,
        isVendorImageLoading: state.VendorOrderListReducer.isVendorImageLoading,
        vendorImage: state.VendorOrderListReducer.vendorImage,
    };
};

const mapDispatchToprops = (dispatch) => {
    return {
        getVendorOrderList: (
            pageNo,
            dodgeInvoice,
            jupiterInvoice,
            partNumber,
            selectedSearchByOption,
            dateStart,
            dateEnd
        ) => {
            dispatch(VendorOrderListMiddleware.getVendorOrderList(
                pageNo,
                dodgeInvoice,
                jupiterInvoice,
                partNumber,
                selectedSearchByOption,
                dateStart,
                dateEnd
            ));
        },
        getVendorImage: (
            image
        ) => {
            dispatch(VendorOrderListMiddleware.getVendorImage(
                image
            ));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToprops)(VendorOrdersScreen);
