class AuthActions {
    static AUTHENTICATION_REQUEST = "AUTHENTICATION_REQUEST";
    static AUTHENTICATION_SUCCESSFUL = "AUTHENTICATION_SUCCESSFUL";
    static AUTHENTICATION_FAILED = "AUTHENTICATION_FAILED";
    static LOGOUT = "LOGOUT";
  
    static authentication() {
      return {
        type: this.AUTHENTICATION_REQUEST
      };
    }
    static authenticationSuccessul(data) {
      return {
        type: this.AUTHENTICATION_SUCCESSFUL,
        data
      };
    }
    static authenticationFailed(error) {
      return {
        type: this.AUTHENTICATION_FAILED,
        error
      };
    }


    static USER_DATA_REQUEST = "USER_DATA_REQUEST";
    static USER_DATA_SUCCESSFUL = "USER_DATA_SUCCESSFUL";
    static USER_DATA_FAILED = "USER_DATA_FAILED";
    static LOGOUT = "LOGOUT";
  
    static userDataLoading() {
      return {
        type: this.USER_DATA_REQUEST
      };
    }
    static userDataSuccessul(data) {
      return {
        type: this.USER_DATA_SUCCESSFUL,
        data
      };
    }
    static userDataFailed(error) {
      return {
        type: this.USER_DATA_FAILED,
        error
      };
    }
  
    static logout() {
      localStorage.removeItem("wpd_org_usr");
      localStorage.removeItem("wpd_org_usr_tkn");
      localStorage.clear();
      return {
        type: this.LOGOUT
      };
    }
  }
  
  export default AuthActions;
  