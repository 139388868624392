import React, { useEffect, useState, useRef } from 'react';
import BellIcon from '../../assets/images/bell-icon.svg';
import DropdownIcon from '../../assets/images/dropdown-arrow.svg';
import SettingsIcon from '../../assets/images/settings.svg';
import ExitIcon from '../../assets/images/exit.svg';
import AuthMiddleware from "../../store/middlewares/authMiddleware";
import { connect } from 'react-redux';
import './topNavBar.styles.scss';

const TopNavBar = (props) => {
    const node = useRef();
    var user = JSON.parse(localStorage.getItem('wpd_org_usr'));

    const [isCollapsed, setIscollapsed] = useState(true);

    const handleLogout = (e) => {
        e.preventDefault();

        props.logOut();
    }

    useEffect(() => {
        // add when mounted
        document.addEventListener("mousedown", handleClick);
        // return function to be called when unmounted
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    const handleClick = e => {
        if (node.current.contains(e.target)) {
            // inside click
            return;
        }
        setIscollapsed(true)
    };

    return (
        <div className='nav-bar'>

            <div className='nav-logo'>
                World Parts Direct
            </div>

            <div className='nav-right-items'>

                <div className='notification-badge'>
                    <img src={BellIcon} alt='' />
                </div>

                <div className='top-nav-user-name-hover' onClick={() => setIscollapsed(!isCollapsed)} ref={node}>
                    <div className='top-nav-user-name' style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                        {(props.userData && props.userData?.data.name) || (user && user.name)}
                        <img className='top-nav-drop-icon' src={DropdownIcon} alt='' />
                    </div>

                    <div className={isCollapsed ? 'top-nav-drop-down-collapsed' : 'top-nav-drop-down-open'}>

                        <div className='top-drop-items-container'>
                            <img className='drop-item-img' src={SettingsIcon} alt='' />
                            <span>Settings</span>
                        </div>

                        <div className='top-drop-items-container' onClick={e => handleLogout(e)}>
                            <img className='drop-item-img' src={ExitIcon} alt='' />
                            <span>Logout</span>
                        </div>

                    </div>
                </div>

            </div>

        </div>
    )
}


const mapStateToProps = state => {
    return {
        userData: state.AuthReducer.userData
    };
};

const mapDispatchToProps = dispatch => {
    return {
        logOut: () => {
            dispatch(AuthMiddleware.logOut());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopNavBar);
