import OrderListActions from "../actions/orderListActions";

const OrderListReducer = (state = {
  isOrderListLoading: false, orderListHasError: false, isTableSyncLoading: false, tableSyncHasError: false,
  isTableFilterCountLoading: false, tableFilterCountHasError: false
}, action) => {
  switch (action.type) {
    case OrderListActions.ORDER_LIST_REQUEST:
      return {
        ...state,
        isOrderListLoading: true,
        orderList: null,
        orderListHasError: false
      };
    case OrderListActions.ORDER_LIST_SUCCESSFUL:
      return {
        ...state,
        isOrderListLoading: false,
        orderList: action.data,
        orderListHasError: false
      };
    case OrderListActions.ORDER_LIST_FAILED:
      return {
        ...state,
        errorMessage: {},
        isOrderListLoading: false,
        orderListHasError: true,
        orderList: null
      };

    // ************ TABLE SYNC REDUCER ********
    case OrderListActions.TABLE_SYNC_REQUEST:
      return {
        ...state,
        isTableSyncLoading: true,
        tableSync: null,
        tableSyncHasError: false
      };
    case OrderListActions.TABLE_SYNC_SUCCESSFUL:
      return {
        ...state,
        isTableSyncLoading: false,
        tableSync: action.data,
        tableSyncHasError: false
      };
    case OrderListActions.TABLE_SYNC_FAILED:
      return {
        ...state,
        errorMessage: {},
        isTableSyncLoading: false,
        tableSyncHasError: true,
        tableSync: null
      };

    // ************ TABLE FILTER COUNT REDUCER ********
    case OrderListActions.TABLE_FILTER_COUNT_REQUEST:
      return {
        ...state,
        isTableFilterCountLoading: true,
        tableFilterCount: null,
        tableFilterCountHasError: false
      };
    case OrderListActions.TABLE_FILTER_COUNT_SUCCESSFUL:
      return {
        ...state,
        isTableFilterCountLoading: false,
        tableFilterCount: action.data,
        tableFilterCountHasError: false
      };
    case OrderListActions.TABLE_FILTER_COUNT_FAILED:
      return {
        ...state,
        errorMessage: {},
        isTableFilterCountLoading: false,
        tableFilterCountHasError: true,
        tableFilterCount: null
      };
    default:
      return {
        ...state
      };
  }
};

export default OrderListReducer;
