class OrderDetailsActions {

    //********** GET ORDER DETAILS SUMMARY **********
    static GET_ORDER_DETAILS_REQUEST = "GET_ORDER_DETAILS_REQUEST";
    static GET_ORDER_DETAILS_SUCCESSFUL = "GET_ORDER_DETAILS_SUCCESSFUL";
    static GET_ORDER_DETAILS_FAILED = "GET_ORDER_DETAILS_FAILED";

    static getOderDetailsRequest() {
        return {
            type: this.GET_ORDER_DETAILS_REQUEST
        };
    }
    static getOderDetailsSuccessul(data) {
        return {
            type: this.GET_ORDER_DETAILS_SUCCESSFUL,
            data
        };
    }
    static getOderDetailsFailed(error) {
        return {
            type: this.GET_ORDER_DETAILS_FAILED,
            error
        };
    }


    //********** GET ORDER DETAILS INVOICES **********
    static GET_ORDER_DETAILS_INVOICES_REQUEST = "GET_ORDER_DETAILS_INVOICES_REQUEST";
    static GET_ORDER_DETAILS_INVOICES_SUCCESSFUL = "GET_ORDER_DETAILS_INVOICES_SUCCESSFUL";
    static GET_ORDER_DETAILS_INVOICES_FAILED = "GET_ORDER_DETAILS_INVOICES_FAILED";

    static getOderDetailsInovicesRequest() {
        return {
            type: this.GET_ORDER_DETAILS_INVOICES_REQUEST
        };
    }
    static getOderDetailsInovicesSuccessul(data) {
        return {
            type: this.GET_ORDER_DETAILS_INVOICES_SUCCESSFUL,
            data
        };
    }
    static getOderDetailsInovicesFailed(error) {
        return {
            type: this.GET_ORDER_DETAILS_INVOICES_FAILED,
            error
        };
    }


    //********** GET ORDER DETAILS ITEMS **********
    static GET_ORDER_DETAILS_ITEMS_REQUEST = "GET_ORDER_DETAILS_ITEMS_REQUEST";
    static GET_ORDER_DETAILS_ITEMS_SUCCESSFUL = "GET_ORDER_DETAILS_ITEMS_SUCCESSFUL";
    static GET_ORDER_DETAILS_ITEMS_FAILED = "GET_ORDER_DETAILS_ITEMS_FAILED";

    static getOderDetailsItemsRequest() {
        return {
            type: this.GET_ORDER_DETAILS_ITEMS_REQUEST
        };
    }
    static getOderDetailsItemsSuccessul(data) {
        return {
            type: this.GET_ORDER_DETAILS_ITEMS_SUCCESSFUL,
            data
        };
    }
    static getOderDetailsItemsFailed(error) {
        return {
            type: this.GET_ORDER_DETAILS_ITEMS_FAILED,
            error
        };
    }
}

export default OrderDetailsActions;
