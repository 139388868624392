import VendorOrderListActions from "../actions/vendorOrderListActions";
import AuthMiddleware from './authMiddleware';
import CustomToast from '../../components/custom-toast/customToast';
import Path from "../../services/paths";
import axios from 'axios';

class VendorOrderListMiddleware {
    static getVendorOrderList(
        pageNo,
        dodgeInvoice,
        jupiterInvoice,
        partNumber,
        selectedSearchByOption,
        dateStart,
        dateEnd
    ) {
        let user_tkn = JSON.parse(localStorage.getItem('wpd_org_usr_tkn'))
        let url = `${Path.VENDOR_ORDER_LIST}?`;

        if (pageNo) {
            url += `page=${pageNo}&`;
        }
        if (dodgeInvoice) {
            url += `dodge_invoice=${dodgeInvoice}&`;
        }
        if (jupiterInvoice) {
            url += `jupiter_invoice=${jupiterInvoice}&`;
        }
        if (partNumber) {
            url += `part_number=${partNumber}&`;
        }
        if (selectedSearchByOption?.value) {
            url += `date_filter_by=${selectedSearchByOption.value}&`;
        }
        if (dateStart) {
            url += `date_start=${dateStart}&`;
        }
        if (dateEnd) {
            url += `date_end=${dateEnd}&`;
        }

        url = url.slice(0, -1);

        return dispatch => {
            dispatch(VendorOrderListActions.vendorOrderListRequest());
            axios({
                method: "GET",
                url: url,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `JWT ${user_tkn.access}`
                }
            })
                .then(res => {
                    if (res.status === 200) {
                        dispatch(VendorOrderListActions.vendorOrderListSuccessul(res.data));
                    } else {
                        CustomToast('error', 'Order List API Failed')
                        dispatch(VendorOrderListActions.vendorOrderListFailed());
                    }
                })
                .catch(error => {
                    console.log({ error })
                    if (error.response?.status === 401) {
                        CustomToast('error', 'Auth token expire!')
                        dispatch(AuthMiddleware.refreshToken(user_tkn.refresh))
                    }
                    dispatch(VendorOrderListActions.vendorOrderListFailed());
                });
        };
    }

    static getVendorImage(
        image
    ) {
        let user_tkn = JSON.parse(localStorage.getItem('wpd_org_usr_tkn'))
        let url = `${Path.VENDOR_IMAGE}?invoice_name=${image}`;

        return dispatch => {
            dispatch(VendorOrderListActions.vendorImagetRequest());
            fetch(
                url,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "image/png",
                        Authorization: `JWT ${user_tkn.access}`
                    }
                }
            )
                .then(response => response.blob())
                .then(function (myBlob) {
                    if (myBlob) {
                        var objectURL = URL.createObjectURL(myBlob);
                        dispatch(VendorOrderListActions.vendorImageSuccessul(objectURL));
                    }
                    else {
                        CustomToast('error', 'Image loading Failed')
                        dispatch(VendorOrderListActions.vendorImageFailed());
                    }
                })
                .catch(error => {
                    console.log({ error })
                    if (error.response?.status === 401) {
                        CustomToast('error', 'Auth token expire!')
                        dispatch(AuthMiddleware.refreshToken(user_tkn.refresh))
                    }
                    dispatch(VendorOrderListActions.vendorImageFailed());
                });
        };
    }
}

export default VendorOrderListMiddleware;
