import React from "react";
import { toast } from 'react-toastify';
import WarningIcon from '../../assets/images/warning-tri.svg';
import ErrorIcon from '../../assets/images/error-cross.svg';
import SuccessIcon from '../../assets/images/success-tick.svg';
import './customToast.styles.scss';

const CustomToast = (type, message) => {
    switch (type) {
        case 'warning':
            return toast(<div className="toast-text-container">
            <img src={WarningIcon} className="toast-icon" alt=''/>
             Warning: {message}
             </div>, 
             {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            })
        case 'error':
            return toast(<div className="toast-text-container">
                <img src={ErrorIcon} className="toast-icon" alt=''/>
                 Error: {typeof message == "string" && message}
                 </div>, 
                 {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                })
        case 'success':
            return toast(<div className="toast-text-container">
            <img src={SuccessIcon} className="toast-icon" alt=''/>
             Success: {message}
             </div>, 
             {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            })
        default:
            return toast(message)
    }
}
export default CustomToast;