import React from 'react';
import './simpleTable.styles.scss';

const SimpleTable = ({
    headers,
    tableData,
    tableType
}) => {

    return (
        <table className='simple-table'>
            <thead>
                <tr>
                    {
                        headers && headers.map(item => (
                            <th key={item} className='cell-padding-wrap header-cell-bg'>
                                {item}
                            </th>
                        ))
                    }
                </tr>
            </thead>

            <tbody>
                {
                    tableType === 'invoicesTable' && tableData && tableData.map((item, ind) => (
                        <tr key={ind}>
                            <td className='cell-padding-wrap cell-text-align-center'>{item.invoice_number}</td>
                            <td className='cell-padding-wrap cell-text-align-center'>{item.open_date}</td>
                            <td className='cell-padding-wrap cell-text-align-center'>{item.close_date}</td>
                            <td className='cell-padding-wrap cell-text-align-center'>{item.user_name}</td>
                            <td className='cell-padding-wrap cell-text-align-center'>{item.total_sale}</td>
                            <td className='cell-padding-wrap cell-text-align-center'>{item.total_cost}</td>
                        </tr>
                    ))
                }

                {
                    tableType === 'shippingSumTable' && tableData && tableData.map((item, ind) => (
                        <tr key={ind}>
                            <td className='cell-padding-wrap cell-text-align-center'>{item.tracking_number}</td>
                            <td className='cell-padding-wrap cell-text-align-center'>{item.shipping_carrier}</td>
                            <td className='cell-padding-wrap cell-text-align-center'>{item.ship_cost}</td>
                            <td className='cell-padding-wrap cell-text-align-center'>{item.service}</td>
                        </tr>
                    ))
                }

                {
                    tableType === 'invoiceSumTable' && tableData && tableData.map((item, ind) => (
                        <tr key={ind}>
                            <td className='cell-padding-wrap cell-text-align-center'>{item.invoice_number}</td>
                            <td className='cell-padding-wrap cell-text-align-center'>{item.store_customer_number}</td>
                            <td className='cell-padding-wrap cell-text-align-center'>{item.user}</td>
                            <td className='cell-padding-wrap cell-text-align-center'>{item.total_sale}</td>
                            <td className='cell-padding-wrap cell-text-align-center'>{item.total_cost}</td>
                        </tr>
                    ))
                }
            </tbody>
        </table>
    )
};

export default SimpleTable;
