class VendorOrderListActions {
    static VENDOR_ORDER_LIST_REQUEST = "VENDOR_ORDER_LIST_REQUEST";
    static VENDOR_ORDER_LIST_SUCCESSFUL = "VENDOR_ORDER_LIST_SUCCESSFUL";
    static VENDOR_ORDER_LIST_FAILED = "VENDOR_ORDER_LIST_FAILED";

    static vendorOrderListRequest() {
        return {
            type: this.VENDOR_ORDER_LIST_REQUEST
        };
    }
    static vendorOrderListSuccessul(data) {
        return {
            type: this.VENDOR_ORDER_LIST_SUCCESSFUL,
            data
        };
    }
    static vendorOrderListFailed(error) {
        return {
            type: this.VENDOR_ORDER_LIST_FAILED,
            error
        };
    }

    //******** VENDOR IMAGE ACTIONS

    static VENDOR_IMAGE_REQUEST = "VENDOR_IMAGE_REQUEST";
    static VENDOR_IMAGE_SUCCESSFUL = "VENDOR_IMAGE_SUCCESSFUL";
    static VENDOR_IMAGE_FAILED = "VENDOR_IMAGE_FAILED";

    static vendorImagetRequest() {
        return {
            type: this.VENDOR_IMAGE_REQUEST
        };
    }
    static vendorImageSuccessul(data) {
        return {
            type: this.VENDOR_IMAGE_SUCCESSFUL,
            data
        };
    }
    static vendorImageFailed(error) {
        return {
            type: this.VENDOR_IMAGE_FAILED,
            error
        };
    }
}

export default VendorOrderListActions;
