import React, { useEffect, useState, useRef } from 'react';
import FunnelFilter from '../../../assets/images/funnel-filter.svg';
import SyncIcon from '../../../assets/images/sync.svg';
import ReactPaginate from 'react-paginate';
import LoaderSVG from '../../../assets/svgs/loader.svg';
import './orderScreenTable.styles.scss';
import UtilFunction from '../../../services/utilsFunctions';
import { useHistory } from 'react-router-dom';

const OrderScreenTable = (
    {
        headers,
        data,
        colHandler,
        allColumnShow,
        allColHandler,
        handlePageClick,
        isOrderListLoading,
        pageNo,
        handleSyncCall,
        totalPg,
        isTableSyncLoading,

        //COLUMN DRAGING PROPS
        handleDragStart,
        handleDragOver,
        handleOnDrop
    }) => {

    const node = useRef();
    const history = useHistory();

    const [showFilters, setShowFilter] = useState(false);

    useEffect(() => {
        // add when mounted
        document.addEventListener("mousedown", handleClick);
        // return function to be called when unmounted
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    const handleClick = e => {
        if (node.current.contains(e.target)) {
            // inside click
            return;
        }
        setShowFilter(false)
    };

    return (
        <div className='order-hub-table-filter-container'>
            {
                isOrderListLoading ?

                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <img style={{ width: '50px' }} src={LoaderSVG} alt='' />
                    </div>

                    :

                    <>

                        <div className='pagination-filter-container' >

                            <div className='funnel-filter-container' title="Sync Data">
                                <div className='sub-container' onClick={handleSyncCall}>
                                    <img className={isTableSyncLoading ? 'sync-loading' : 'sync-icon'} src={SyncIcon} alt='' style={{ width: '17px' }} />
                                </div>
                            </div>

                            <ReactPaginate
                                previousLabel={'<<'}
                                nextLabel={">>"}
                                pageCount={totalPg}
                                forcePage={pageNo - 1}
                                pageRangeDisplayed={3}
                                marginPagesDisplayed={1}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                previousLinkClassName={"pagination__link"}
                                nextLinkClassName={"pagination__link"}
                                disabledClassName={"pagination__link--disabled"}
                                activeClassName={"pagination__link--active"}
                            />

                            <div className='funnel-filter-container' ref={node}>
                                <div className='sub-container' onClick={() => setShowFilter(!showFilters)}>
                                    <img src={FunnelFilter} alt='' />
                                </div>
                                {
                                    showFilters ?
                                        <div className='column-filters-container'>
                                            <span style={{ display: 'flex', marginBottom: '4px', borderBottom: '1px solid lightgray' }} >
                                                <input type='checkbox' checked={allColumnShow} onChange={() => allColHandler(allColumnShow)} />
                                                <label>Select All</label>
                                            </span>
                                            <div className='column-sub-filters-container' >
                                                {
                                                    headers.map((header, ind) => (
                                                        <span key={header.name + ind}
                                                            style={(header.name === 'Order Date' || header.name === 'Order Number' || header.name === 'Marketplace') ?
                                                                { display: 'none' } : { display: 'flex', marginBottom: '4px' }} >
                                                            <input type='checkbox' checked={header.show_column}
                                                                onChange={() => { colHandler(ind) }}
                                                            />
                                                            <label>{header.name}</label>
                                                        </span>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                        :
                                        null
                                }
                            </div>
                        </div>

                        {
                            headers.length && data ? 
                            <div className='order-table-container'>

                            <table className='order-table'>
                                <thead>
                                    <tr>
                                        {
                                            headers.length &&
                                            headers.sort((a, b) => a.index - b.index)
                                                .map((header, ind) => (
                                                    <th
                                                        key={header.name + ind}
                                                        className={header.show_column ? 'cell-padding-wrap cell-text-align-left header-cell-bg' : 'hidden-col'}
                                                        id={header.name}
                                                        draggable
                                                        onDragStart={handleDragStart}
                                                        onDragOver={handleDragOver}
                                                        onDrop={handleOnDrop}
                                                    >
                                                        {header.name}
                                                    </th>
                                                ))
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data && data.map((row, ind) => (
                                            <tr key={`row${ind}`} style={{cursor:'pointer'}} onClick={()=>{history.push(`/orderdetails/${row.id}`)}} className='row-highligh'>
                                                {
                                                    Object.entries(row).map((cellData, cellInd) => (
                                                        <td key={`td${cellInd}`} className={headers[cellInd]?.show_column ? 'cell-padding-wrap' : 'hidden-col'}>
                                                            {
                                                                row[headers[cellInd]?.enum] === true ? 'True' :
                                                                    row[headers[cellInd]?.enum] === false ? 'False' :
                                                                        (headers[cellInd]?.name)?.includes('Date') ? UtilFunction.dateFormater2(row[headers[cellInd]?.enum]) :
                                                                            row[headers[cellInd]?.enum]
                                                            }
                                                        </td>
                                                    ))
                                                }
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>

                        </div>
                        :
                        <span className="no-data-text">
                            No Data Found!
                        </span>
                        }

                    </>
            }
        </div>
    );
}
export default OrderScreenTable;
