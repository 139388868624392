import OrderListActions from "../actions/orderListActions";
import AuthMiddleware from './authMiddleware';
import CustomToast from '../../components/custom-toast/customToast';
import Path from "../../services/paths";
import axios from 'axios';

class OrderListMiddleware {
    static getOrderList(
        pageNo,
        orderQuery,
        selectedSearchByOption,
        orderNumber,
        invoiceNumber,
        partNumber,
        dateStart,
        dateEnd,
        selectedOrderStatus,
        columnMetaData,
        selectedShippingCarrier,
        selectedMarketPlaces,
        minPrice,
        maxPrice,
        minCost,
        maxCost,
        dispute,
        returned,
        balanced,
        escalated,
        forged
    ) {
        let user_tkn = JSON.parse(localStorage.getItem('wpd_org_usr_tkn'))
        let url = `${Path.ORDER_LIST}?`;

        if (pageNo) {
            url += `page=${pageNo}&`;
        }
        if (orderQuery) {
            url += `order_query=${orderQuery}&`;
        }
        if (selectedSearchByOption) {
            url += `search_by=${selectedSearchByOption}&`;
        }
        if (orderNumber) {
            url += `order_number=${orderNumber}&`;
        }
        if (invoiceNumber) {
            url += `invoice_number=${invoiceNumber}&`;
        }
        if (partNumber) {
            url += `part_number=${partNumber}&`;
        }
        if (dateStart) {
            url += `date_start=${dateStart}&`;
        }
        if (dateEnd) {
            url += `date_end=${dateEnd}&`;
        }
        if (selectedOrderStatus) {
            url += `order_status=${selectedOrderStatus}&`;
        }
        if (columnMetaData || !columnMetaData) {
            url += `column_metadata=${columnMetaData}&`;
        }
        if (selectedShippingCarrier) {
            url += `shipping_carrier=${selectedShippingCarrier}&`;
        }
        if (selectedMarketPlaces) {
            url += `marketplace=${selectedMarketPlaces}&`;
        }
        if (minPrice) {
            url += `price_min=${minPrice}&`;
        }
        if (maxPrice) {
            url += `price_max=${maxPrice}&`;
        }
        if (minCost) {
            url += `cost_min=${minCost}&`;
        }
        if (maxCost) {
            url += `cost_max=${maxCost}&`;
        }
        if (dispute || dispute === false) {
            url += `dispute=${dispute}&`;
        }
        if (returned || returned === false) {
            url += `returned=${returned}&`;
        }
        if (balanced || balanced === false) {
            url += `balance=${balanced}&`;
        }
        if (escalated || escalated === false) {
            url += `escalated=${escalated}&`;
        }
        if (forged || forged === false) {
            url += `forged=${forged}&`;
        }

        url = url.slice(0, -1);

        return dispatch => {
            dispatch(OrderListActions.orderListRequest());
            axios({
                method: "GET",
                url: url,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `JWT ${user_tkn.access}`
                }
            })
                .then(res => {
                    if (res.status === 200) {
                        dispatch(OrderListActions.orderListSuccessul(res.data));
                    } else {
                        CustomToast('error', 'Order List API Failed')
                        dispatch(OrderListActions.orderListFailed());
                    }
                })
                .catch(error => {
                    console.log({ error })
                    if (error.response?.status === 401) {
                        CustomToast('error', 'Auth token expire!')
                        dispatch(AuthMiddleware.refreshToken(user_tkn.refresh))
                    }
                    dispatch(OrderListActions.orderListFailed());
                });
        };
    }

    static putTableSync(
        columnMetaData
    ) {
        let user_tkn = JSON.parse(localStorage.getItem('wpd_org_usr_tkn'))
        let url = `${Path.TABLE_SYNC}`;

        return dispatch => {
            dispatch(OrderListActions.tableSyncRequest());
            axios({
                method: "PUT",
                url: url,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `JWT ${user_tkn.access}`
                },
                data: JSON.stringify([...columnMetaData])
            })
                .then(res => {
                    if (res.status === 200) {
                        dispatch(OrderListActions.tableSyncSuccessul(res.data));
                        CustomToast('success', 'Table Sync Successful')
                    } else {
                        CustomToast('error', 'Table Sync API Failed')
                        dispatch(OrderListActions.tableSyncFailed());
                    }
                })
                .catch(error => {
                    console.log({ error })
                    if (error.response?.status === 401) {
                        CustomToast('error', 'Auth token expire!')
                        dispatch(AuthMiddleware.refreshToken(user_tkn.refresh))
                    } else {
                        CustomToast('error', 'Table Sync API Failed')
                    }
                    dispatch(OrderListActions.tableSyncFailed());
                });
        };
    }

    static getTableFiltersCount(
        selectedOrderStatus,
        selectedShippingCarrier,
        selectedMarketPlaces,
    ) {
        let user_tkn = JSON.parse(localStorage.getItem('wpd_org_usr_tkn'))
        let url = `${Path.TABLE_FILTER_COUNT}?`;

        if (selectedOrderStatus) {
            url += `order_status=${selectedOrderStatus}&`;
        }
        if (selectedShippingCarrier) {
            url += `shipping_carrier=${selectedShippingCarrier}&`;
        }
        if (selectedMarketPlaces) {
            url += `marketplace=${selectedMarketPlaces}&`;
        }

        url = url.slice(0, -1);

        return dispatch => {
            dispatch(OrderListActions.tableFilterCountRequest());
            axios({
                method: "GET",
                url: url,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `JWT ${user_tkn.access}`
                }
            })
                .then(res => {
                    if (res.status === 200) {
                        dispatch(OrderListActions.tableFilterCountSuccessul(res.data));
                    } else {
                        CustomToast('error', 'Table Filter Count API Failed')
                        dispatch(OrderListActions.tableFilterCountFailed());
                    }
                })
                .catch(error => {
                    console.log({ error })
                    if (error.response?.status === 401) {
                        CustomToast('error', 'Auth token expire!')
                        dispatch(AuthMiddleware.refreshToken(user_tkn.refresh))
                    } else {
                        CustomToast('error', 'Table Filter Count API Failed')
                    }
                    dispatch(OrderListActions.tableFilterCountFailed());
                });
        };
    }
}

export default OrderListMiddleware;
