import OrderDetailsActions from "../actions/orderDetailsActions";
import AuthMiddleware from './authMiddleware';
import CustomToast from '../../components/custom-toast/customToast';
import Path from "../../services/paths";
import axios from 'axios';

class OrderDetailsMiddleware {
    static getOrderDetails(
        id
    ) {
        let user_tkn = JSON.parse(localStorage.getItem('wpd_org_usr_tkn'))
        let url = `${Path.BASE_URL}orders/${id}/order-details/`;

        return dispatch => {
            dispatch(OrderDetailsActions.getOderDetailsRequest());
            axios({
                method: "GET",
                url: url,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `JWT ${user_tkn.access}`
                }
            })
                .then(res => {
                    if (res.status === 200) {
                        dispatch(OrderDetailsActions.getOderDetailsSuccessul(res.data));
                    } else {
                        CustomToast('error', 'Order Details API Failed')
                        dispatch(OrderDetailsActions.getOderDetailsFailed());
                    }
                })
                .catch(error => {
                    console.log({ error })
                    if (error.response?.status === 401) {
                        CustomToast('error', 'Auth token expire!')
                        dispatch(AuthMiddleware.refreshToken(user_tkn.refresh))
                    }
                    CustomToast('error', 'No Order Found!')
                    dispatch(OrderDetailsActions.getOderDetailsFailed());
                });
        };
    };

    static getOrderDetailsInvoices(
        id
    ) {
        let user_tkn = JSON.parse(localStorage.getItem('wpd_org_usr_tkn'))
        let url = `${Path.BASE_URL}orders/${id}/invoices/`;

        return dispatch => {
            dispatch(OrderDetailsActions.getOderDetailsInovicesRequest());
            axios({
                method: "GET",
                url: url,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `JWT ${user_tkn.access}`
                }
            })
                .then(res => {
                    if (res.status === 200) {
                        dispatch(OrderDetailsActions.getOderDetailsInovicesSuccessul(res.data));
                    } else {
                        CustomToast('error', 'Order Details API Failed')
                        dispatch(OrderDetailsActions.getOderDetailsInovicesFailed());
                    }
                })
                .catch(error => {
                    console.log({ error })
                    if (error.response?.status === 401) {
                        CustomToast('error', 'Auth token expire!')
                        dispatch(AuthMiddleware.refreshToken(user_tkn.refresh))
                    }
                    CustomToast('error', 'No Order Found!')
                    dispatch(OrderDetailsActions.getOderDetailsInovicesFailed());
                });
        };
    };

    static getOrderDetailsItems(
        id
    ) {
        let user_tkn = JSON.parse(localStorage.getItem('wpd_org_usr_tkn'))
        let url = `${Path.BASE_URL}orders/${id}/items/`;

        return dispatch => {
            dispatch(OrderDetailsActions.getOderDetailsItemsRequest());
            axios({
                method: "GET",
                url: url,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `JWT ${user_tkn.access}`
                }
            })
                .then(res => {
                    if (res.status === 200) {
                        dispatch(OrderDetailsActions.getOderDetailsItemsSuccessul(res.data));
                    } else {
                        CustomToast('error', 'Order Details API Failed')
                        dispatch(OrderDetailsActions.getOderDetailsItemsFailed());
                    }
                })
                .catch(error => {
                    console.log({ error })
                    if (error.response?.status === 401) {
                        CustomToast('error', 'Auth token expire!')
                        dispatch(AuthMiddleware.refreshToken(user_tkn.refresh))
                    }
                    CustomToast('error', 'No Order Found!')
                    dispatch(OrderDetailsActions.getOderDetailsItemsFailed());
                });
        };
    };
};

export default OrderDetailsMiddleware;
