import React from 'react';
import LoaderSVG from '../../assets/svgs/loader.svg';
import SimpleTable from '../tables/simple-table/simpleTable';
import './orderSummary.styles.scss';

const OrderSummary = ({
    isOrderDetailsLoading,
    orderDetails
}) => {
    
    return (
        <div className='order-summary-container'>
            {
                isOrderDetailsLoading && !orderDetails ?

                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <img style={{ width: '50px' }} src={LoaderSVG} alt='' />
                    </div>

                    :

                    <>
                        <div className='os-cd-wrapper'>
                            <div className='os-div'>
                                <h1 className='os-heading'>Order Summary</h1>
                                <div className='os-data-container'>
                                    <div style={{ width: '50%' }}>
                                        <div className='label-data-container'>
                                            <label className='os-labels'>SS Order ID:</label>
                                            <span className='os-labels-data'>{orderDetails?.order_summary.order_id}</span>
                                        </div>
                                        <div className='label-data-container'>
                                            <label className='os-labels'>Order Number:</label>
                                            <span className='os-labels-data'>{orderDetails?.order_summary.order_number}</span>
                                        </div>
                                        <div className='label-data-container'>
                                            <label className='os-labels'>Shipping:</label>
                                            <span className='os-labels-data'>{orderDetails?.order_summary.shipping_total}</span>
                                        </div>
                                    </div>
                                    <div style={{ width: '50%' }}>
                                        <div className='label-data-container'>
                                            <label className='os-labels'>Order Total:</label>
                                            <span className='os-labels-data'>${orderDetails?.order_summary.order_total}</span>
                                        </div>
                                        <div className='label-data-container'>
                                            <label className='os-labels'>Tax:</label>
                                            <span className='os-labels-data'>${orderDetails?.order_summary.tax}</span>
                                        </div>
                                        <div className='label-data-container'>
                                            <label className='os-labels'><u>Grand Total:</u></label>
                                            <span className='os-labels-data'>${orderDetails?.order_summary.grand_total}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='customer-div'>
                                <h1 className='os-heading'>Customer Details</h1>
                                <div className='os-data-container'>
                                    <div style={{ width: '50%' }}>
                                        <div className='label-data-container'>
                                            <label className='os-labels'>Name:</label>
                                            <span className='os-labels-data'>{orderDetails?.customer_details.name}</span>
                                        </div>
                                        <div className='label-data-container'>
                                            <label className='os-labels'>Phone#:</label>
                                            <span className='os-labels-data'>{orderDetails?.customer_details.phone}</span>
                                        </div>
                                        <div className='label-data-container'>
                                            <label className='os-labels'>Country:</label>
                                            <span className='os-labels-data'>{orderDetails?.customer_details.country}</span>
                                        </div>
                                        <div className='label-data-container'>
                                            <label className='os-labels'>State:</label>
                                            <span className='os-labels-data'>{orderDetails?.customer_details.state}</span>
                                        </div>
                                    </div>
                                    <div style={{ width: '50%' }}>
                                        <div className='label-data-container'>
                                            <label className='os-labels'>Email:</label>
                                            <span className='os-labels-data'>{orderDetails?.customer_details.email}</span>
                                        </div>
                                        <div className='label-data-container'>
                                            <label className='os-labels'>Address:</label>
                                            <span className='os-labels-data'>{orderDetails?.customer_details.address}</span>
                                        </div>
                                        <div className='label-data-container'>
                                            <label className='os-labels'>City:</label>
                                            <span className='os-labels-data'>{orderDetails?.customer_details.city}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='customer-div margin-btn'>
                            <h1 className='os-heading'>Shipping Summary</h1>
                            {
                                orderDetails?.shipping_summary && orderDetails?.shipping_summary.length ?
                                    <SimpleTable
                                        headers={['Tracking Number', 'Shipping Carrier', 'Shipping Cost', 'Service']}
                                        tableData={orderDetails?.shipping_summary}
                                        tableType={'shippingSumTable'}
                                    />
                                    :
                                    <div style={{ textAlign: 'center' }}>
                                        <span className="no-data-text">
                                            No Data Found!
                                        </span>
                                    </div>

                            }
                        </div>
                        <div className='customer-div margin-btn'>
                            <h1 className='os-heading'>Invoice Summary</h1>
                            {
                                orderDetails?.invoice_summary && orderDetails?.invoice_summary.length ?
                                    <SimpleTable
                                        headers={['Invoice Number', 'Store Customer Number', 'User', 'Total Sale', 'Total Cost']}
                                        tableData={orderDetails?.invoice_summary}
                                        tableType={'invoiceSumTable'}
                                    />
                                    :
                                    <div style={{ textAlign: 'center' }}>
                                        <span className="no-data-text">
                                            No Data Found!
                                        </span>
                                    </div>

                            }
                        </div>
                        <div className='customer-div margin-btn'>
                            <h1 className='os-heading'>Purchasing Summary</h1>
                            <div style={{ textAlign: 'center' }}>
                                <span className="no-data-text">
                                    No Data Found!
                    </span>
                            </div>
                        </div>
                        <div className='customer-div'>
                            <h1 className='os-heading'>Recent Activity</h1>
                            <div style={{ textAlign: 'center' }}>
                                <span className="no-data-text">
                                    No Data Found!
                    </span>
                            </div>
                        </div>
                    </>
            }
        </div>
    )
};

export default OrderSummary;
