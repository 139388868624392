import FiltersActions from "../actions/filtersActions";
import AuthMiddleware from './authMiddleware';
import CustomToast from '../../components/custom-toast/customToast';
import Path from "../../services/paths";
import axios from 'axios';

class FiltersMiddleware {
    static getOrderHubFilters() {
        let user_tkn = JSON.parse(localStorage.getItem('wpd_org_usr_tkn'))
        return dispatch => {
            dispatch(FiltersActions.orderHubFiltersRequest());
            axios({
                method: "GET",
                url: Path.ORDER_HUB_FILTERS,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `JWT ${user_tkn.access}`
                }
            })
                .then(res => {
                    if (res.status === 200) {
                        dispatch(FiltersActions.orderHubFiltersSuccessul(res.data));
                    } else {
                        CustomToast('error', 'Filters API Failed')
                        dispatch(FiltersActions.orderHubFiltersFailed());
                    }
                })
                .catch(error => {
                    if (error.response?.status === 401) {
                        CustomToast('error', 'Auth token expire!')
                        dispatch(AuthMiddleware.refreshToken(user_tkn.refresh))
                    }
                    dispatch(FiltersActions.orderHubFiltersFailed());
                });
        };
    }
}

export default FiltersMiddleware;
