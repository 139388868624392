import React from 'react';
import './orderHubTags.styles.scss';

const OrderHubTags = ({tags}) => (
    
    <div className='oder-hub-tags-container'>
        {
            tags && tags.map((tag,ind)=>(
                <span key={tag.label+ind} className='order-hub-tag' >
                    {tag.label} ({tag.count})
                </span>
            ))
        }

        {tags?.length === 0 && <span className='no-data-text'>No Tags Found!</span>}
    </div>
);

export default OrderHubTags;
