import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CustomToast from '../../components/custom-toast/customToast';
import AuthMiddleware from '../../store/middlewares/authMiddleware';
import './loginScreen.styles.scss';

const LoginScreen = (props) => {

    const [userName, setUserName] = useState('');
    const [userPassword, setPassword] = useState('');
    const history = useHistory();

    const onSubmit = (e) => {
        e.preventDefault();

        if (userName && userPassword) {
            props.authenticate(userName, userPassword, history)
        } else {
            CustomToast('error', 'Email or Password is empty!');
        }
    }

    const handleEnter = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            onSubmit(e)
        }
    }

    return (

        <div className='login-box-container'>

            <div className='login-box'>

                <div className='login-heading-container'>
                    <span style={{ color: '#828282', fontSize: '13px' }}>Welcome to </span>
                    <span style={{ fontSize: '18px', fontWeight: 600 }}>World Parts Direct</span>
                </div>

                <div className='login-label-input'>
                    {/* <label className='login-label'>Email</label><br/> */}
                    <input className='login-input' type='text' value={userName} onKeyDown={(e) => handleEnter(e)} onChange={(e) => setUserName(e.target.value)} placeholder='Username' />
                </div>

                <div className='login-label-input'>
                    {/* <label className='login-label'>Password</label><br/> */}
                    <input className='login-input' type='password' value={userPassword} onKeyDown={(e) => handleEnter(e)} onChange={(e) => setPassword(e.target.value)} placeholder='Password' /><br />
                    <span className='forgot-password'>Forgot password?</span>
                </div>

                {
                    props.isLoading ?
                        <button className='login-submit' >Loading...</button>
                        :
                        <button className='login-submit' onClick={(e) => onSubmit(e)}>Sign In</button>
                }

            </div>

        </div>

    )
}


const mapStateToProps = (state) => {
    return {
        isLoading: state.AuthReducer.isLoading,
    };
};

const mapDispatchToprops = (dispatch) => {
    return {
        authenticate: (username, password, history) => {
            dispatch(AuthMiddleware.authenticate(username, password, history));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToprops)(LoginScreen);


