import React, { useEffect } from 'react';
import { Route, BrowserRouter, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import AuthMiddleware from './store/middlewares/authMiddleware';
import HomeScreen from './screens/home-screen/homeScreen';
import LoginScreen from './screens/login-screen/loginScreen';
import OrdersScreen from './screens/orders-screen/ordersScreen';
import Layout from './components/layout/layout';
import OrderDetailScreen from './screens/order-details-screen/orderDetailScreen';
import VendorOrdersScreen from './screens/vendor-orders-screen/vendorOrdersScreen';


const PrivateRoute = ({ component: Component, isLoggedIn, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) => isLoggedIn === true
                ? <Component {...props} />
                : <Redirect to={{ pathname: '/', state: { from: props.location } }} />}
        />
    )
}

const NonPrivateRoute = ({ component: Component, isLoggedIn, ...rest }) => {
    var user = JSON.parse(localStorage.getItem('wpd_org_usr_tkn'));
    if(user){
        if(user?.groups[0] === 'vendor' ? true : false){
            return (
                <Route
                    {...rest}
                    render={props =>
                        isLoggedIn === false ? (
                            <Component {...props} />
                        ) : (
                                <Redirect to={{ pathname: '/vendororders' }} />
                            )
                    }
                />
            );
        }
        else{
            return (
                <Route
                    {...rest}
                    render={props =>
                        isLoggedIn === false ? (
                            <Component {...props} />
                        ) : (
                                <Redirect to={{ pathname: '/home' }} />
                            )
                    }
                />
            );
        }
    }else{
            return (
                <Route
                    {...rest}
                    render={props =>
                        isLoggedIn === false ? (
                            <Component {...props} />
                        ) : (
                                <Redirect to={{ pathname: '/home' }} />
                            )
                    }
                />
            );
        }
}

const wrappedRoutes = () => {
    var user = JSON.parse(localStorage.getItem('wpd_org_usr_tkn'));

    return(
    <Layout>
        <Switch>
            <PrivateRoute isLoggedIn={user?.groups[0] !== 'vendor' ? true : false} path="/home" component={HomeScreen} />
            <PrivateRoute isLoggedIn={user?.groups[0] !== 'vendor' ? true : false} path="/orders" component={OrdersScreen} />
            <PrivateRoute isLoggedIn={user?.groups[0] !== 'vendor' ? true : false} path="/orderdetails/:id" component={OrderDetailScreen} />
            <PrivateRoute isLoggedIn={true} path="/vendororders" component={VendorOrdersScreen} />
        </Switch>
    </Layout>
    );
};

const Routes = (props) => {
    var user = JSON.parse(localStorage.getItem('wpd_org_usr_tkn'));

    useEffect(() => {
        var userExpd = JSON.parse(localStorage.getItem('wpd_org_usr_tkn_expd'));
        var today = new Date();
        var expDate = new Date(userExpd);

        if (((today >= expDate)) && userExpd) {
            props.refreshToken(user.refresh)
        }
        // eslint-disable-next-line 
    }, [])

    return (
        <div>
            <BrowserRouter>
                <Switch>
                    <NonPrivateRoute
                        isLoggedIn={user ? true : false}
                        exact
                        path="/"
                        component={LoginScreen}
                    />
                    <PrivateRoute
                        isLoggedIn={user ? true : false}
                        path="/"
                        component={wrappedRoutes}
                    />
                </Switch>
            </BrowserRouter>
        </div>
    );
}

const mapStateToProps = state => {
    const { isLoggedIn } = state.AuthReducer;
    return {
        isLoggedIn
    };
};

const mapDispatchToprops = (dispatch) => {
    return {
        refreshToken: (refreshToken) => {
            dispatch(AuthMiddleware.refreshToken(refreshToken));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToprops)(Routes);
