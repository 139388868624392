import React from 'react';
import ReactPaginate from 'react-paginate';
import LoaderSVG from '../../../assets/svgs/loader.svg';
import UtilFunction from '../../../services/utilsFunctions';
import './vendorOrderTable.styles.scss';

const VendorOrderTable = (
    {
        data,
        handlePageClick,
        isVendorOrderListLoading,
        pageNo,
        totalPg,
        handleOpenModal
    }) => {

    return (
        <div className='order-hub-table-filter-container' style={{maxHeight: 'calc(100vh - 300px)'}}>
            {
                isVendorOrderListLoading ?

                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <img style={{ width: '50px' }} src={LoaderSVG} alt='' />
                    </div>

                    :

                    <>

                        <div className='pagination-filter-container' >

                            <ReactPaginate
                                previousLabel={'<<'}
                                nextLabel={">>"}
                                pageCount={totalPg}
                                forcePage={pageNo - 1}
                                pageRangeDisplayed={3}
                                marginPagesDisplayed={1}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                previousLinkClassName={"pagination__link"}
                                nextLinkClassName={"pagination__link"}
                                disabledClassName={"pagination__link--disabled"}
                                activeClassName={"pagination__link--active"}
                            />

                        </div>

                        {
                            data && data.length ?
                                <div className='order-table-container'>

                                    <table className='order-table table-pos-rel'>
                                        <thead>
                                            <tr>
                                                <th className='cell-padding-wrap cell-text-align-left header-cell-bg fixed-th'>ID</th>
                                                <th className='cell-padding-wrap cell-text-align-left header-cell-bg fixed-th'>JUP INVOICE</th>
                                                <th className='cell-padding-wrap cell-text-align-left header-cell-bg fixed-th'>JUP DATE</th>
                                                <th className='cell-padding-wrap cell-text-align-left header-cell-bg fixed-th'>DODGE INV</th>
                                                <th className='cell-padding-wrap cell-text-align-left header-cell-bg fixed-th'>ALT DODGE INV</th>
                                                <th className='cell-padding-wrap cell-text-align-left header-cell-bg fixed-th'>DODGE DATE</th>
                                                <th className='cell-padding-wrap cell-text-align-left header-cell-bg fixed-th'>DODGE CLOSED</th>
                                                <th className='cell-padding-wrap cell-text-align-left header-cell-bg fixed-th'>REQ PART</th>
                                                <th className='cell-padding-wrap cell-text-align-left header-cell-bg fixed-th'>SOURCE</th>
                                                <th className='cell-padding-wrap cell-text-align-left header-cell-bg fixed-th'>COST</th>
                                                <th className='cell-padding-wrap cell-text-align-left header-cell-bg fixed-th'>CORE</th>
                                                <th className='cell-padding-wrap cell-text-align-left header-cell-bg fixed-th'>ORDER QTY</th>
                                                <th className='cell-padding-wrap cell-text-align-left header-cell-bg fixed-th'>SCAN QTY</th>
                                                <th className='cell-padding-wrap cell-text-align-left header-cell-bg fixed-th'>SCAN PART</th>
                                                <th className='cell-padding-wrap cell-text-align-left header-cell-bg fixed-th'>SCAN PO</th>
                                                <th className='cell-padding-wrap cell-text-align-left header-cell-bg fixed-th'>SCAN DATE</th>
                                                <th className='cell-padding-wrap cell-text-align-left header-cell-bg fixed-th'>SCANNED INVOICE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                data && data.map((row) => (
                                                    <tr key={`row${row.id}`} className='row-highligh'>
                                                        <td className='cell-padding-wrap'>{row.id}</td>
                                                        <td className='cell-padding-wrap'>{row.jupiter_invoice}</td>
                                                        <td className='cell-padding-wrap'>{UtilFunction.dateFormater2(row.jupiter_date)}</td>
                                                        <td className='cell-padding-wrap'>{row.dodge_invoice}</td>
                                                        <td className='cell-padding-wrap'>{row.alt_dodge_invoice}</td>
                                                        <td className='cell-padding-wrap'>{UtilFunction.dateFormater2(row.dodge_date)}</td>
                                                        <td className='cell-padding-wrap'>{UtilFunction.dateFormater2(row.dodge_closed)}</td>
                                                        <td className='cell-padding-wrap'>{row.requested_part}</td>
                                                        <td className='cell-padding-wrap'>{row.source}</td>
                                                        <td className='cell-padding-wrap'>{row.cost}</td>
                                                        <td className='cell-padding-wrap'>{row.core}</td>
                                                        <td className='cell-padding-wrap'>{row.order_quantity}</td>
                                                        <td className='cell-padding-wrap'>{row.scan_quantity}</td>
                                                        <td className='cell-padding-wrap'>{row.scan_part}</td>
                                                        <td className='cell-padding-wrap'>{row.scan_po}</td>
                                                        <td className='cell-padding-wrap'>{UtilFunction.dateFormater2(row.scan_date)}</td>
                                                        <td className='cell-padding-wrap' style={{cursor:'pointer'}} onClick={()=>row.scanned_invoice ? handleOpenModal(row.scanned_invoice) : void 0}>{row.scanned_invoice ? 'View Invoice' : null}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>

                                </div>
                                :
                                <span className="no-data-text">
                                    No Data Found!
                        </span>
                        }

                    </>
            }
        </div>
    );
}
export default VendorOrderTable;
