import VendorOrderListActions from "../actions/vendorOrderListActions";

const VendorOrderListReducer = (state = {
  isVendorOrderListLoading: false, vendorOrderListHasError: false,
  isVendorImageLoading: false, vendorImageHasError: false
}, action) => {
  switch (action.type) {
    case VendorOrderListActions.VENDOR_ORDER_LIST_REQUEST:
      return {
        ...state,
        isVendorOrderListLoading: true,
        vendorOrderList: null,
        vendorOrderListHasError: false
      };
    case VendorOrderListActions.VENDOR_ORDER_LIST_SUCCESSFUL:
      return {
        ...state,
        isVendorOrderListLoading: false,
        vendorOrderList: action.data,
        vendorOrderListHasError: false
      };
    case VendorOrderListActions.VENDOR_ORDER_LIST_FAILED:
      return {
        ...state,
        errorMessage: {},
        isVendorOrderListLoading: false,
        vendorOrderListHasError: true,
        vendorOrderList: null
      };
      
      //********  VENDOR IMAGE REDUCER
    case VendorOrderListActions.VENDOR_IMAGE_REQUEST:
      return {
        ...state,
        isVendorImageLoading: true,
        vendorImage: null,
        vendorImageHasError: false
      };
    case VendorOrderListActions.VENDOR_IMAGE_SUCCESSFUL:
      return {
        ...state,
        isVendorImageLoading: false,
        vendorImage: action.data,
        vendorImageHasError: false
      };
    case VendorOrderListActions.VENDOR_IMAGE_FAILED:
      return {
        ...state,
        errorMessage: {},
        isVendorImageLoading: false,
        vendorImageHasError: true,
        vendorImage: null
      };
    default:
      return {
        ...state
      };
  }
};

export default VendorOrderListReducer;
