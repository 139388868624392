import './tabs.styles.scss';

const Tabs = ({
    tabsArray,
    onClickCallback,
    selectedTab
}) => (
        <div className='tabs-container'>

            {
                tabsArray && tabsArray.map(item => (
                    <span key={item.id} className={`tab-style ${item.id === selectedTab ? 'active-tab' : ''}`} onClick={() => { onClickCallback(item.id) }}>
                        {item.label}
                    </span>
                ))
            }

        </div>
    );

export default Tabs;
