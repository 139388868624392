import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import AuthReducer from "./reducers/authReducer";
import FiltersReducer from './reducers/filtersReducer';
import OrderListReducer from './reducers/orderListReducer';
import VendorOrderListReducer from './reducers/vendorOrderListReducer';
import OrderDetailsReducer from './reducers/orderDetailsReducer';

const rootReducer = combineReducers({
    AuthReducer,
    FiltersReducer,
    OrderListReducer,
    VendorOrderListReducer,
    OrderDetailsReducer
});

const store = createStore(
    rootReducer, applyMiddleware(thunk, logger)
);

export default store;
