export default class Path {

    //  ****************   APP BASE URLS ********************

    static AUTH_URL = process.env.REACT_APP_AUTH_URL;

    static BASE_URL = process.env.REACT_APP_BASE_URL;



    //  ****************   USER AUTH AND INFO URLS ********************


    static AUTHENTICATE = `${Path.AUTH_URL}jwt/create/`;

    static USER_DATA = `${Path.AUTH_URL}users/me/`;

    static REFRESH_TOKEN = `${Path.AUTH_URL}jwt/refresh/`;



    //  ****************   APP API URLS ********************


    static ORDER_HUB_FILTERS = `${Path.BASE_URL}enums/filters/`;

    static ORDER_LIST = `${Path.BASE_URL}orders/`;

    static TABLE_SYNC = `${Path.BASE_URL}orders/column-metadata/`;

    static TABLE_FILTER_COUNT = `${Path.BASE_URL}orders/filters-count/`;

    static VENDOR_ORDER_LIST = `${Path.BASE_URL}vendors/part-orders/`;

    static VENDOR_IMAGE = `${Path.BASE_URL}vendors/part-orders/scanned-invoice/`;
}