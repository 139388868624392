import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import OrderScreenFilters from '../../components/filters/order-screen-filters/orderScreenFilters';
import OrderScreenTable from '../../components/tables/order-screen-table/orderScreenTable';
import OrderHubTags from '../../components/tags/order-hub-tags/orderHubTags';
import FiltersMiddleware from '../../store/middlewares/filtersMiddleware';
import UtilFunction from '../../services/utilsFunctions';
import OrderListMiddleware from '../../store/middlewares/orderListMiddleware';

const OrdersScreen = (props) => {

    const [tableHeaders, setTableHeaders] = useState([]);
    const [tableData, setTableData] = useState([])
    const [allColumnShow, setAllColumnShow] = useState(true);
    const [marketPlaceFilters, setMarketPlaceFilter] = useState([]);
    const [orderStatusFilters, setOrderStatusFilters] = useState([]);
    const [othersBooleanFilters, setOthersBooleanFilters] = useState([]);
    const [searchByFilters, setSearchByFilters] = useState([]);
    const [shippingCarrierFilters, setShippingCarrierFilters] = useState([]);
    const [orderQuery, setOrderQuery] = useState('');
    const [orderNumber, setOrderNumber] = useState('');
    const [invoiceNumber, setInvoiceNumber] = useState('');
    const [partNumber, setPartNumber] = useState('');
    const [dateStart, setDateStart] = useState(null);
    const [dateStartToDisplay, setDateStartToDisplay] = useState(null);
    const [dateEnd, setDateEnd] = useState(null);
    const [dateEndToDisplay, setDateEndToDisplay] = useState(null);
    const [minPrice, setMinPrice] = useState('');
    const [maxPrice, setMaxPrice] = useState('');
    const [minCost, setMinCost] = useState('');
    const [maxCost, setMaxCost] = useState('');
    const [selectedSearchByOption, setSearchByOption] = useState(null);
    const [selectedOrderStatus, setOrderStatus] = useState('');
    const [selectedMarketPlaces, setMarketPlaces] = useState('');
    const [selectedShippingCarrier, setShippingCarrier] = useState('');
    const [pageNo, setPageNo] = useState(1);
    const [columnMetaData] = useState(true); // setColumnMetaData
    const [dispute, setDispute] = useState(null);
    const [balanced, setBalanced] = useState(null);
    const [forged, setForged] = useState(null);
    const [returned, setReturned] = useState(null);
    const [escalated, setEscalated] = useState(null);
    const [totalPage, setTotalPage] = useState(0);

    //************ TABLE COLUMN DRAG WORK START ********

    const handleDragStart = e => {
        const { id } = e.target;
        let idx = null;
        tableHeaders.forEach((element, index) => {
            if (element.name === id) {
                idx = index
            }
        });
        e.dataTransfer.setData("colIdx", idx);
    };

    const handleDragOver = e => e.preventDefault();

    const handleOnDrop = e => {
        const { id } = e.target;
        let droppedColIdx = null;
        tableHeaders.forEach((element, index) => {
            if (element.name === id) {
                droppedColIdx = index
            }
        });
        const draggedColIdx = e.dataTransfer.getData("colIdx");
        const tempCols = [...tableHeaders];

        tempCols[draggedColIdx] = tableHeaders[droppedColIdx];
        tempCols[droppedColIdx] = tableHeaders[draggedColIdx];

        tempCols[draggedColIdx] = { ...tempCols[draggedColIdx], index: draggedColIdx }
        tempCols[droppedColIdx] = { ...tempCols[droppedColIdx], index: droppedColIdx }
        setTableHeaders(tempCols);
    };
    //************ */ TABLE COLUMN DRAG WORK END ***********

    useEffect(() => {
        props.getOrderHubFilters();
        callOrderListApi();
    }, [])           // eslint-disable-line

    const callTableFilterCount = (orderStatus, marketplace, shippingCarrier) => {

        if (orderStatus && marketplace && shippingCarrier) {
            let orderStatusString = '';
            let marketplaceString = '';
            let shippingCarrierString = '';

            let orderStatusArray = [];
            let marketplaceArray = [];
            let shippingCarrierArray = [];

            orderStatus.forEach(item => {
                orderStatusArray.push(item.enum)
            })
            marketplace.forEach(item => {
                marketplaceArray.push(item.enum)
            })
            shippingCarrier.forEach(item => {
                shippingCarrierArray.push(item.enum)
            })

            orderStatusString = orderStatusArray.join(',');
            marketplaceString = marketplaceArray.join(',');
            shippingCarrierString = shippingCarrierArray.join(',');

            props.getTableFiltersCount(
                orderStatusString,
                shippingCarrierString,
                marketplaceString,
            )
        }
    }

    useEffect(() => {
        if (props.filters) {
            setMarketPlaceFilter(props.filters.MARKETPLACE);
            setOrderStatusFilters(props.filters.ORDER_STATUS);
            setOthersBooleanFilters(props.filters.OTHERS_BOOLEAN);
            setSearchByFilters(props.filters.SEARCH_BY);
            setShippingCarrierFilters(props.filters.SHIPPING_CARRIER);
            callTableFilterCount(props.filters.ORDER_STATUS, props.filters.MARKETPLACE, props.filters.SHIPPING_CARRIER);
        }
    }, [props.filters])             // eslint-disable-line

    useEffect(() => {
        if (props.orderList) {
            if (props.orderList.column_metadata?.length && props.orderList.results?.length) {
                setTableHeaders(props.orderList.column_metadata)
                setTableData(props.orderList.results)
                setTotalPage(props.orderList.total_pages);
            }
        } else {
            setTableHeaders([])
            setTableData([])
        }
    }, [props.orderList])

    useEffect(() => {
        if (props.tableSync) {
            if (props.tableSync.length) {
                setTableHeaders(props.tableSync)
            }
        }
    }, [props.tableSync])

    const handleColumns = (index) => {

        let newArray = [...tableHeaders]
        newArray[index] = { ...newArray[index], show_column: !newArray[index].show_column }
        setTableHeaders(newArray)
    }

    const handleAllColumns = (value) => {

        let newArray = [...tableHeaders]
        tableHeaders.forEach((header, ind) => {
            if (header.name === 'Order Date' || header.name === 'Order Number' || header.name === 'Marketplace') {

            } else {
                newArray[ind] = { ...newArray[ind], show_column: !value }
            }
        })
        setTableHeaders(newArray)
        setAllColumnShow(!value)
    }

    useEffect(() => {
        let count = 0;
        tableHeaders.forEach(item => {
            if (!item.show_column) {
                count++;
            }
        })
        if (count > 0) {
            setAllColumnShow(false)
        } else {
            setAllColumnShow(true)
        }
    }, [tableHeaders])

    const handleMarketPlaceFilter = (index) => {

        let newArray = [...marketPlaceFilters]
        let selectedValues = []
        newArray[index] = { ...newArray[index], flag: !newArray[index].flag }

        let filterIsTrue = newArray.filter(item => item.flag)
        filterIsTrue.forEach(item => {
            selectedValues.push(item.enum)
        })
        let createString = `${selectedValues.join(',')}`
        setMarketPlaces(createString)
        setMarketPlaceFilter(newArray)
    }

    const handleOrderStatusFilters = (index) => {

        let newArray = [...orderStatusFilters]
        let selectedValues = []
        newArray[index] = { ...newArray[index], flag: !newArray[index].flag }

        let filterIsTrue = newArray.filter(item => item.flag)
        filterIsTrue.forEach(item => {
            selectedValues.push(item.enum)
        })
        let createString = `${selectedValues.join(',')}`
        setOrderStatus(createString)
        setOrderStatusFilters(newArray)
    }

    const handleShippingCarrierFilters = (index) => {

        let newArray = [...shippingCarrierFilters]
        let selectedValues = []
        newArray[index] = { ...newArray[index], flag: !newArray[index].flag }

        let filterIsTrue = newArray.filter(item => item.flag)
        filterIsTrue.forEach(item => {
            selectedValues.push(item.enum)
        })
        let createString = `${selectedValues.join(',')}`
        setShippingCarrier(createString)
        setShippingCarrierFilters(newArray)
    }

    const handleOthersBooleanFilters = (index, value) => {

        let newArray = [...othersBooleanFilters]
        newArray[index] = { ...newArray[index], flag: value }

        newArray.forEach(item => {
            if (item.label === 'Dispute') {
                setDispute(item.flag)
            }
            else if (item.label === 'Balanced') {
                setBalanced(item.flag)
            }
            else if (item.label === 'Forged') {
                setForged(item.flag)
            }
            else if (item.label === 'Returned') {
                setReturned(item.flag)
            }
            else if (item.label === 'Escalated') {
                setEscalated(item.flag)
            }
        })

        setOthersBooleanFilters(newArray)
    }

    const handleSearchByFilters = (index) => {

        let newArray = [...searchByFilters]
        newArray[index] = { ...newArray[index], flag: !newArray[index].flag }
        setSearchByFilters(newArray)
    }

    const handleOrderQueryChange = (e) => {
        e.preventDefault();
        setOrderQuery(e.target.value)
    }

    const handleOrderNumberChange = (e) => {
        e.preventDefault();
        setOrderNumber(e.target.value)
    }

    const handleInvoiceNumberChange = (e) => {
        e.preventDefault();
        setInvoiceNumber(e.target.value)
    }

    const handlePartNumberChange = (e) => {
        e.preventDefault();
        setPartNumber(e.target.value)
    }

    const handleStartDateChange = (value) => {
        if (value) {
            let date = UtilFunction.filterDateFormater(value);
            setDateStart(date)
            setDateStartToDisplay(value)
        } else {
            setDateStart(null)
            setDateStartToDisplay(null)
        }
    }

    const handleEndDateChange = (value) => {
        if (value) {
            let date = UtilFunction.filterDateFormater(value);
            setDateEnd(date)
            setDateEndToDisplay(value)
        } else {
            setDateEnd(null)
            setDateEndToDisplay(null)
        }
    }

    const handleSearchByOptionChange = (selectvalue) => {
        if (selectvalue) {
            setSearchByOption(selectvalue.enum)
        } else {
            setSearchByOption(null)
        }
    }

    const handleMinPriceChange = (e) => {
        e.preventDefault();
        setMinPrice(e.target.value)
    }

    const handleMaxPriceChange = (e) => {
        e.preventDefault();
        setMaxPrice(e.target.value)
    }

    const handleMinCostChange = (e) => {
        e.preventDefault();
        setMinCost(e.target.value)
    }

    const handleMaxCostChange = (e) => {
        e.preventDefault();
        setMaxCost(e.target.value)
    }

    const handlePageClick = (value) => {
        setPageNo(value.selected + 1)
        callOrderListApi(value.selected + 1)
    }
    const handleSearch = (e) => {
        e.preventDefault();
        setPageNo(1);
        callOrderListApi(1);
        props.getTableFiltersCount(
            selectedOrderStatus,
            selectedShippingCarrier,
            selectedMarketPlaces,
        )
    }

    const callOrderListApi = (page) => {
        let pageNumber = page ? page : pageNo;
        props.getOrderList(
            pageNumber,
            orderQuery,
            selectedSearchByOption,
            orderNumber,
            invoiceNumber,
            partNumber,
            dateStart,
            dateEnd,
            selectedOrderStatus,
            columnMetaData,
            selectedShippingCarrier,
            selectedMarketPlaces,
            minPrice,
            maxPrice,
            minCost,
            maxCost,
            dispute,
            returned,
            balanced,
            escalated,
            forged
        )
    }

    const handleSyncCall = (e) => {
        e.preventDefault();
        if (tableHeaders.length) {
            let columnMetaData = tableHeaders;
            columnMetaData.forEach((item, ind) => {
                item.index = ind;
            })
            props.putTableSync(columnMetaData)
        }
    }

    const handleClearFilters = () => {

        setOrderQuery('');
        setSearchByOption(null);
        setInvoiceNumber('');
        setOrderNumber('');
        setPartNumber('');
        setDateStart(null);
        setDateEnd(null);
        setDateStartToDisplay(null);
        setDateEndToDisplay(null);
        setOrderStatus('');
        setShippingCarrier('');
        setMarketPlaces('');
        setMinPrice('');
        setMaxPrice('');
        setMinCost('');
        setMaxCost('');
        setDispute(null);
        setBalanced(null);
        setForged(null);
        setReturned(null);
        setEscalated(null);
        setPageNo(1);

        let orderStatusTempArray = [...orderStatusFilters]
        let marketplaceTempArray = [...marketPlaceFilters]
        let shippingCarrierTempArray = [...shippingCarrierFilters]
        let othersBooleanTempArray = [...othersBooleanFilters]

        orderStatusTempArray.forEach(item => {
            item.flag = false
        })
        marketplaceTempArray.forEach(item => {
            item.flag = false
        })
        shippingCarrierTempArray.forEach(item => {
            item.flag = false
        })
        othersBooleanTempArray.forEach(item => {
            item.flag = null
        })

        setOrderStatusFilters(orderStatusTempArray)
        setMarketPlaceFilter(marketplaceTempArray)
        setShippingCarrierFilters(shippingCarrierTempArray)
        setOthersBooleanFilters(othersBooleanTempArray)

        props.getOrderList(
            1,
            '',         // orderQuery
            null,       // selectedSearchByOption
            '',         // orderNumber
            '',         // invoiceNumber
            '',         // partNumber
            null,       // dateStart
            null,       // dateEnd
            '',         // selectedOrderStatus
            true,       // columnMetaData
            '',         // selectedShippingCarrier
            '',         // selectedMarketPlaces
            '',         // minPrice
            '',         // maxPrice
            '',         // minCost
            '',         // maxCost
            null,       // dispute
            null,       // returned
            null,       // balanced
            null,       // escalated
            null        // forged
        )

        props.getTableFiltersCount(
            '',        // selectedOrderStatus
            '',       // selectedShippingCarrier
            '',      // selectedMarketPlaces
        )
    }

    return (
        <div>

            <OrderScreenFilters
                marketPlaceFilters={marketPlaceFilters}
                orderStatusFilters={orderStatusFilters}
                othersBooleanFilters={othersBooleanFilters}
                searchByFilters={searchByFilters}
                shippingCarrierFilters={shippingCarrierFilters}
                orderQuery={orderQuery}
                orderNumber={orderNumber}
                invoiceNumber={invoiceNumber}
                partNumber={partNumber}
                dateStart={dateStartToDisplay}
                dateEnd={dateEndToDisplay}
                selectedSearchByOption={selectedSearchByOption}
                minPrice={minPrice}
                maxPrice={maxPrice}
                minCost={minCost}
                maxCost={maxCost}

                handleMarketPlaceFilter={handleMarketPlaceFilter}
                handleOrderStatusFilters={handleOrderStatusFilters}
                handleOthersBooleanFilters={handleOthersBooleanFilters}
                handleSearchByFilters={handleSearchByFilters}
                handleShippingCarrierFilters={handleShippingCarrierFilters}
                handleOrderQueryChange={handleOrderQueryChange}
                handleOrderNumberChange={handleOrderNumberChange}
                handleInvoiceNumberChange={handleInvoiceNumberChange}
                handlePartNumberChange={handlePartNumberChange}
                handleStartDateChange={handleStartDateChange}
                handleEndDateChange={handleEndDateChange}
                handleSearch={handleSearch}
                handleSearchByOptionChange={handleSearchByOptionChange}
                handleMinPriceChange={handleMinPriceChange}
                handleMaxPriceChange={handleMaxPriceChange}
                handleMinCostChange={handleMinCostChange}
                handleMaxCostChange={handleMaxCostChange}
                handleClearFilters={handleClearFilters}

                isFitlersLoading={props.isFitlersLoading}
            />

            <OrderHubTags
                tags={props.tableFilterCount && props.tableFilterCount}
            />

            <OrderScreenTable
                headers={tableHeaders}
                data={tableData}
                pageNo={pageNo}
                colHandler={handleColumns}
                allColHandler={handleAllColumns}
                allColumnShow={allColumnShow}
                handlePageClick={handlePageClick}
                isOrderListLoading={props.isOrderListLoading}
                handleSyncCall={handleSyncCall}
                isTableSyncLoading={props.isTableSyncLoading}
                totalPg={totalPage}

                //COLUMN DRAGING PROPS
                handleDragStart={handleDragStart}
                handleDragOver={handleDragOver}
                handleOnDrop={handleOnDrop}
            />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        isFitlersLoading: state.FiltersReducer.isFitlersLoading,
        filters: state.FiltersReducer.filters,
        isOrderListLoading: state.OrderListReducer.isOrderListLoading,
        orderList: state.OrderListReducer.orderList,
        isTableSyncLoading: state.OrderListReducer.isTableSyncLoading,
        tableSync: state.OrderListReducer.tableSync,
        isTableFilterCountLoading: state.OrderListReducer.isTableFilterCountLoading,
        tableFilterCount: state.OrderListReducer.tableFilterCount,
    };
};

const mapDispatchToprops = (dispatch) => {
    return {
        getOrderHubFilters: () => {
            dispatch(FiltersMiddleware.getOrderHubFilters());
        },
        getOrderList: (
            pageNo,
            orderQuery,
            selectedSearchByOption,
            orderNumber,
            invoiceNumber,
            partNumber,
            dateStart,
            dateEnd,
            selectedOrderStatus,
            columnMetaData,
            selectedShippingCarrier,
            selectedMarketPlaces,
            minPrice,
            maxPrice,
            minCost,
            maxCost,
            dispute,
            returned,
            balanced,
            escalated,
            forged
        ) => {
            dispatch(OrderListMiddleware.getOrderList(
                pageNo,
                orderQuery,
                selectedSearchByOption,
                orderNumber,
                invoiceNumber,
                partNumber,
                dateStart,
                dateEnd,
                selectedOrderStatus,
                columnMetaData,
                selectedShippingCarrier,
                selectedMarketPlaces,
                minPrice,
                maxPrice,
                minCost,
                maxCost,
                dispute,
                returned,
                balanced,
                escalated,
                forged
            ));
        },
        putTableSync: (
            columnMetaData
        ) => {
            dispatch(OrderListMiddleware.putTableSync(
                columnMetaData
            ))
        },
        getTableFiltersCount: (
            selectedOrderStatus,
            selectedShippingCarrier,
            selectedMarketPlaces,
        ) => {
            dispatch(OrderListMiddleware.getTableFiltersCount(
                selectedOrderStatus,
                selectedShippingCarrier,
                selectedMarketPlaces,
            ))
        }
    };
};

export default connect(mapStateToProps, mapDispatchToprops)(OrdersScreen);
