import React from 'react';
import './vendorOrderFilters.styles.scss';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const VendorOrderScreenFilters = (props) => {


    return (

        <div className='order-filters-container'>
            <div className='visible-filter-row'>

                <div className='common-hidden-input-box' style={{width:'100%'}}>
                    <div className='common-hidden-input-label-box'>
                        <label>Jupiter Invoice</label>
                        <input className='common-hidden-input' name='jupiterInvoice' placeholder='Jupiter Number' value={props.jupiterInvoice} onChange={props.handleJupiterInvoiceChange} />
                    </div>
                    <div className='common-hidden-input-label-box'>
                        <label>Dodge Invoice</label>
                        <input className='common-hidden-input' name='dodgeInvoice' placeholder='Dodge Invoice' value={props.dodgeInvoice} onChange={props.handleDodgeInvoiceChange} />
                    </div>
                    <div className='common-hidden-input-label-box'>
                        <label>Part Number</label>
                        <input className='common-hidden-input' name='partNumber' placeholder='Part Number' value={props.partNumber} onChange={props.handlePartNumberChange} />
                    </div>
                </div>

                <div className='quick-summary-filter-btn-group' style={{ marginRight: '0px', alignItems:'flex-end' }}>
                    <button className='quick-summary-filter-search-btn' onClick={props.handleSearch} style={{height:'38px'}}>
                        Search
                                </button>

                    <button className='quick-summary-filter-clear-btn' onClick={props.handleClearFilters} style={{height:'38px'}}>
                        Clear
                                </button>
                </div>

            </div>

            <div className='hidden-filter-row'>

                <div className='select-filter-by'>
                    <label className='filter-by-label'>Filter By</label>
                    <Select
                        placeholder='Filter By'
                        isClearable
                        value={props.selectedSearchByOption}
                        onChange={props.handleSearchByOptionChange}
                        options={
                            props.searchByFilters && props.searchByFilters.map(item => {
                                return {
                                    value: item.value,
                                    label: item.label
                                }
                            })
                        }
                    />
                </div>

                <div className='date-range-box' style={{width:'100%'}} >
                    <label>Date Range</label>

                    <div className='date-range-input-box'>
                        <DatePicker className='date-range-input' placeholderText='Start Date' dateFormat="MM-dd-yyyy" isClearable selected={props.dateStart} onChange={props.handleStartDateChange} />
                        <DatePicker className='date-range-input' placeholderText='End Date' dateFormat="MM-dd-yyyy" isClearable selected={props.dateEnd} onChange={props.handleEndDateChange} />
                    </div>
                </div>

            </div>
        </div >
    )
}

export default VendorOrderScreenFilters;
