const ORDER_DETAILS_TABS = [
    {
        id: 0,
        label: 'Summary'
    },
    {
        id: 1,
        label: 'Items'
    },
    {
        id: 2,
        label: 'Invoices'
    },
    {
        id: 3,
        label: 'Payments'
    },
    {
        id: 4,
        label: 'Purchases'
    },
    {
        id: 5,
        label: 'Comment Logs'
    }
]
const DATA = { ORDER_DETAILS_TABS };

export default DATA;