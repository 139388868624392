import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import DashboardIcon from '../../assets/images/dashboard-icon.svg';
import OrderIcon from '../../assets/images/order-icon.svg';
import ShippingIcon from '../../assets/images/shipping-icon.svg';
import InventoryIcon from '../../assets/images/inventory-icon.svg';
import SettingIcon from '../../assets/images/settings-white.svg';
import AccountingIcon from '../../assets/images/accounting-icon.svg';
import HandShakeIcon from '../../assets/images/handshake-icon.svg';
import MoparOrderIcon from '../../assets/images/check-list.svg';
import AuthMiddleware from "../../store/middlewares/authMiddleware";
import { connect } from 'react-redux';
import './sideNavBar.styles.scss';

const SideNavBar = (props) => {

    var user = JSON.parse(localStorage.getItem('wpd_org_usr_tkn'));

    const [isCollapsed, setIsCollapsed] = useState(true)
    const [isActive, setIsActive] = useState(0)

    useEffect(() => {
        const value = window.location.pathname === '/home' ? 0
            : window.location.pathname === '/orders' || window.location.pathname.substr(0,window.location.pathname.lastIndexOf('/')) === '/orderdetails' ? 1
                : window.location.pathname === '/dealer' ? 2
                    : window.location.pathname === '/accounting' ? 3
                        : window.location.pathname === '/inventory' ? 4
                            : window.location.pathname === '/shopping' ? 5
                                : window.location.pathname === '/vendororders' ? 7
                                    : 6;
        setIsActive(value)
    }, [])           // eslint-disable-line

    const handleActiveLink = (value) => {
        setIsActive(value)
    }

    return (

        <div className={`side-nav side-bar-fixed ${isCollapsed ? 'side-nav-close' : 'side-nav-open'}`} onMouseEnter={() => setIsCollapsed(false)} onMouseLeave={() => setIsCollapsed(true)}>

            {user && user.groups[0] !== 'vendor' ?
                <div className={`side-nav ${isCollapsed ? 'side-nav-close' : 'side-nav-open'}`}>

                    <Link className={`side-link-item ${isActive === 0 ? 'side-link-active' : ''}`} to={'/home'} onClick={() => handleActiveLink(0)} >
                        <div className='side-link-items-subcontainer'>
                            <img className='side-link-item-icon' src={DashboardIcon} alt='' />
                            {
                                isCollapsed ? null :
                                    <span className='side-link-item-text' >
                                        Dashboard
                            </span>
                            }
                        </div>
                    </Link>

                    <Link className={`side-link-item ${isActive === 1 ? 'side-link-active' : ''}`} to={'/orders'} onClick={() => handleActiveLink(1)}>
                        <div className='side-link-items-subcontainer'>
                            <img className='side-link-item-icon' src={OrderIcon} alt='' />
                            {
                                isCollapsed ? null :
                                    <span className='side-link-item-text'>
                                        Orders
                            </span>
                            }
                        </div>
                    </Link>

                    <Link className={`side-link-item ${isActive === 7 ? 'side-link-active' : ''}`} to={'/vendororders'} onClick={() => handleActiveLink(7)}>
                        <div className='side-link-items-subcontainer'>
                            <img className='side-link-item-icon' src={MoparOrderIcon} alt='' />
                            {
                                isCollapsed ? null :
                                    <span className='side-link-item-text'>
                                        Mopar Orders
                        </span>
                            }
                        </div>
                    </Link>

                    <Link className={`side-link-item ${isActive === 2 ? 'side-link-active' : ''}`} to={'/dealer'} onClick={() => handleActiveLink(2)}>
                        <div className='side-link-items-subcontainer'>
                            <img className='side-link-item-icon' src={HandShakeIcon} alt='' />
                            {
                                isCollapsed ? null :
                                    <span className='side-link-item-text'>
                                        Dealer & Drops
                            </span>
                            }
                        </div>
                    </Link>

                    <Link className={`side-link-item ${isActive === 3 ? 'side-link-active' : ''}`} to={'/accounting'} onClick={() => handleActiveLink(3)}>
                        <div className='side-link-items-subcontainer'>
                            <img className='side-link-item-icon' src={AccountingIcon} alt='' />
                            {
                                isCollapsed ? null :
                                    <span className='side-link-item-text'>
                                        Accounting
                            </span>
                            }
                        </div>
                    </Link>

                    <Link className={`side-link-item ${isActive === 4 ? 'side-link-active' : ''}`} to={'/inventory'} onClick={() => handleActiveLink(4)}>
                        <div className='side-link-items-subcontainer'>
                            <img className='side-link-item-icon' src={InventoryIcon} alt='' />
                            {
                                isCollapsed ? null :
                                    <span className='side-link-item-text'>
                                        Inventory
                            </span>
                            }
                        </div>
                    </Link>

                    <Link className={`side-link-item ${isActive === 5 ? 'side-link-active' : ''}`} to={'/shopping'} onClick={() => handleActiveLink(5)}>
                        <div className='side-link-items-subcontainer'>
                            <img className='side-link-item-icon' src={ShippingIcon} alt='' />
                            {
                                isCollapsed ? null :
                                    <span className='side-link-item-text'>
                                        Shipping
                            </span>
                            }
                        </div>
                    </Link>

                </div>

                :
                null

            }

            {
                user && user.groups[0] === 'vendor' ?
                    <div className={`side-nav ${isCollapsed ? 'side-nav-close' : 'side-nav-open'}`}>

                        <Link className={`side-link-item ${isActive === 1 ? 'side-link-active' : ''}`} to={'/vendororders'} onClick={() => handleActiveLink(1)}>
                            <div className='side-link-items-subcontainer'>
                                <img className='side-link-item-icon' src={MoparOrderIcon} alt='' />
                                {
                                    isCollapsed ? null :
                                        <span className='side-link-item-text'>
                                            Mopar Orders
                        </span>
                                }
                            </div>
                        </Link>

                    </div>
                    : null
            }

            <Link className={`side-link-item ${isActive === 6 ? 'side-link-active' : ''}`} to={'/settings'} onClick={() => handleActiveLink(6)}>
                <div className='side-link-items-subcontainer'>
                    <img className='side-link-item-icon' src={SettingIcon} alt='' />
                    {
                        isCollapsed ? null :
                            <span className='side-link-item-text'>
                                Settings
                </span>
                    }
                </div>
            </Link>

        </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.AuthReducer.user
    };
};

const mapDispatchToProps = dispatch => {
    return {
        logOut: () => {
            dispatch(AuthMiddleware.logOut());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SideNavBar);
