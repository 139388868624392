class OrderListActions {
    static ORDER_LIST_REQUEST = "ORDER_LIST_REQUEST";
    static ORDER_LIST_SUCCESSFUL = "ORDER_LIST_SUCCESSFUL";
    static ORDER_LIST_FAILED = "ORDER_LIST_FAILED";

    static orderListRequest() {
        return {
            type: this.ORDER_LIST_REQUEST
        };
    }
    static orderListSuccessul(data) {
        return {
            type: this.ORDER_LIST_SUCCESSFUL,
            data
        };
    }
    static orderListFailed(error) {
        return {
            type: this.ORDER_LIST_FAILED,
            error
        };
    }

    // *********** TABLE SYNC ACTIONS************
    static TABLE_SYNC_REQUEST = "TABLE_SYNC_REQUEST";
    static TABLE_SYNC_SUCCESSFUL = "TABLE_SYNC_SUCCESSFUL";
    static TABLE_SYNC_FAILED = "TABLE_SYNC_FAILED";

    static tableSyncRequest() {
        return {
            type: this.TABLE_SYNC_REQUEST
        };
    }
    static tableSyncSuccessul(data) {
        return {
            type: this.TABLE_SYNC_SUCCESSFUL,
            data
        };
    }
    static tableSyncFailed(error) {
        return {
            type: this.TABLE_SYNC_FAILED,
            error
        };
    }

    // *********** TABLE FILTER COUNT ACTIONS************
    static TABLE_FILTER_COUNT_REQUEST = "TABLE_FILTER_COUNT_REQUEST";
    static TABLE_FILTER_COUNT_SUCCESSFUL = "TABLE_FILTER_COUNT_SUCCESSFUL";
    static TABLE_FILTER_COUNT_FAILED = "TABLE_FILTER_COUNT_FAILED";

    static tableFilterCountRequest() {
        return {
            type: this.TABLE_FILTER_COUNT_REQUEST
        };
    }
    static tableFilterCountSuccessul(data) {
        return {
            type: this.TABLE_FILTER_COUNT_SUCCESSFUL,
            data
        };
    }
    static tableFilterCountFailed(error) {
        return {
            type: this.TABLE_FILTER_COUNT_FAILED,
            error
        };
    }
}

export default OrderListActions;
