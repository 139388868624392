import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import DATA from '../../assets/data';
import Tabs from '../../components/tabs/tabs';
import './orderDetailScreen.styles.scss';
import OrderSummary from '../../components/order-summary/orderSummary';
import OrderItems from '../../components/order-items/orderItems';
import OrderInvoice from '../../components/order-invoice/orderInvoice';
import OrderDetailsMiddleware from '../../store/middlewares/orderDetailsMiddleware';
import { useHistory } from 'react-router-dom';

const OrderDetailScreen = (props) => {

    const { id } = useParams();
    const history = useHistory();
    const [selectedTab, setSelectedTab] = useState(0);
    const [OrderId, setOrderId] = useState(null);

    const handleTabSelection = (id) => {
        setSelectedTab(id)
    }

    useEffect(() => {
        setOrderId(id);
        props.getOrderDetails(id);
        props.getOrderDetailsInvoices(id);
        props.getOrderDetailsItems(id);
    }, [])           // eslint-disable-line

    return (
        <div>
            <div className='order-details-heading-btn'>
                <div style={{ display: 'flex', alignItems: 'center' }}><strong className='just-order'>Order&nbsp;</strong><span className='order-number'>#{OrderId}</span></div>
                {/* <button className='back-btn'> BACK TO ORDERS</button> */}
                <div className='bto-cta' onClick={() => history.push('/orders')}>
                    <span className='bto-arrow'>&#x21E6;</span>
                    <span className='bto-text'>Back To Orders</span>
                </div>
            </div>

            <Tabs
                tabsArray={DATA.ORDER_DETAILS_TABS}
                onClickCallback={(id) => { handleTabSelection(id) }}
                selectedTab={selectedTab}
            />

            {
                selectedTab === 0 &&
                <OrderSummary
                    isOrderDetailsLoading={props.isOrderDetailsLoading}
                    orderDetails={props.orderDetails}
                />
            }

            {
                selectedTab === 1 &&
                <OrderItems
                    isOrderDetailsItemsLoading={props.isOrderDetailsItemsLoading}
                    orderDetailsItems={props.orderDetailsItems}
                />
            }

            {
                selectedTab === 2 &&
                <OrderInvoice
                    isOrderDetailsInvoicesLoading={props.isOrderDetailsInvoicesLoading}
                    orderDetailsInvoices={props.orderDetailsInvoices}
                />
            }

            {/* {
                selectedTab === 3 && <OrderPayments />
            } */}

        </div>
    )
};

const mapStateToProps = (state) => {
    return {
        isOrderDetailsLoading: state.OrderDetailsReducer.isOrderDetailsLoading,
        orderDetails: state.OrderDetailsReducer.orderDetails,
        isOrderDetailsItemsLoading: state.OrderDetailsReducer.isOrderDetailsItemsLoading,
        orderDetailsItems: state.OrderDetailsReducer.orderDetailsItems,
        isOrderDetailsInvoicesLoading: state.OrderDetailsReducer.isOrderDetailsInvoicesLoading,
        orderDetailsInvoices: state.OrderDetailsReducer.orderDetailsInvoices,
    }
}

const mapDispatchToprops = (dispatch) => {
    return {
        getOrderDetails: (id) => {
            dispatch(OrderDetailsMiddleware.getOrderDetails(id));
        },
        getOrderDetailsInvoices: (id) => {
            dispatch(OrderDetailsMiddleware.getOrderDetailsInvoices(id));
        },
        getOrderDetailsItems: (id) => {
            dispatch(OrderDetailsMiddleware.getOrderDetailsItems(id));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToprops)(OrderDetailScreen);