import OrderDetailsActions from "../actions/orderDetailsActions";

const OrderDetailsReducer = (
  state = {
    isOrderDetailsLoading: false, orderDetailsHasError: false,
    isOrderDetailsInvoicesLoading: false, orderDetailsInvoicesHasError: false,
    isOrderDetailsItemsLoading: false, orderDetailsItemsHasError: false,
  }, action) => {
  switch (action.type) {

    //********** GET ORDER DETAILS SUMMARY **********
    case OrderDetailsActions.GET_ORDER_DETAILS_REQUEST:
      return {
        ...state,
        isOrderDetailsLoading: true,
        orderDetails: null,
        orderDetailsHasError: false
      };
    case OrderDetailsActions.GET_ORDER_DETAILS_SUCCESSFUL:
      return {
        ...state,
        isOrderDetailsLoading: false,
        orderDetails: action.data,
        orderDetailsHasError: false
      };
    case OrderDetailsActions.GET_ORDER_DETAILS_FAILED:
      return {
        ...state,
        errorMessage: {},
        isOrderDetailsLoading: false,
        orderDetailsHasError: true,
        orderDetails: null
      };


    //********** GET ORDER DETAILS INVOICES **********
    case OrderDetailsActions.GET_ORDER_DETAILS_INVOICES_REQUEST:
      return {
        ...state,
        isOrderDetailsInvoicesLoading: true,
        orderDetailsInvoices: null,
        orderDetailsInvoicesHasError: false
      };
    case OrderDetailsActions.GET_ORDER_DETAILS_INVOICES_SUCCESSFUL:
      return {
        ...state,
        isOrderDetailsInvoicesLoading: false,
        orderDetailsInvoices: action.data,
        orderDetailsInvoicesHasError: false
      };
    case OrderDetailsActions.GET_ORDER_DETAILS_INVOICES_FAILED:
      return {
        ...state,
        errorMessage: {},
        isOrderDetailsInvoicesLoading: false,
        orderDetailsInvoicesHasError: true,
        orderDetailsInvoices: null
      };


    //********** GET ORDER DETAILS ITEMS **********
    case OrderDetailsActions.GET_ORDER_DETAILS_ITEMS_REQUEST:
      return {
        ...state,
        isOrderDetailsItemsLoading: true,
        orderDetailsItems: null,
        orderDetailsItemsHasError: false
      };
    case OrderDetailsActions.GET_ORDER_DETAILS_ITEMS_SUCCESSFUL:
      return {
        ...state,
        isOrderDetailsItemsLoading: false,
        orderDetailsItems: action.data,
        orderDetailsItemsHasError: false
      };
    case OrderDetailsActions.GET_ORDER_DETAILS_ITEMS_FAILED:
      return {
        ...state,
        errorMessage: {},
        isOrderDetailsItemsLoading: false,
        orderDetailsItemsHasError: true,
        orderDetailsItems: null
      };
    default:
      return {
        ...state
      };
  }
};

export default OrderDetailsReducer;
