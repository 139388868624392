import React from 'react';
import SimpleTable from '../tables/simple-table/simpleTable';
import LoaderSVG from '../../assets/svgs/loader.svg';
import './orderInvoice.styles.scss';


const OrderItems = ({
    isOrderDetailsInvoicesLoading,
    orderDetailsInvoices
}) => {
    const headers = [
        'Invoice Number',
        'Open Date',
        'Close Date',
        'User',
        'Total Sale',
        'Total Cost'
    ]

    return (
        <>
            {
                isOrderDetailsInvoicesLoading && !orderDetailsInvoices ?

                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <img style={{ width: '50px' }} src={LoaderSVG} alt='' />
                    </div>

                    :
                    <>
                        {
                            orderDetailsInvoices && orderDetailsInvoices?.length ?
                                <div className='oi-container'>
                                    <SimpleTable
                                        headers={headers}
                                        tableData={orderDetailsInvoices}
                                        tableType={'invoicesTable'}
                                    />
                                </div>
                                :
                                <div style={{ textAlign: 'center', width: '100%', marginTop: '112px' }}>
                                    <span className="no-data-text">
                                        No Data Found!
                                    </span>
                                </div>
                        }
                    </>
            }
        </>
    )
};

export default OrderItems;
