import React from 'react';

const HomeScreen = (props) => (

    <div>
        hello
    </div>
);

export default HomeScreen;
